import lazyLoad from '@/utils/lazyLoad';

const routes = [
  {
    path: '/signin',
    name: 'SignIn',
    component: lazyLoad('views/SignIn/Index'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: lazyLoad('views/SignUp/Index'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: lazyLoad('views/ForgotPassword/Index'),
  },
];

export default routes.map((route) => {
  const redirect = '';
  const meta = {
    public: true,
    onlyLoggedOut: true,
  };
  return { ...route, redirect, meta };
});
