import { getField, updateField } from 'vuex-map-fields';
import {
  register as registerInvestor,
  confirm as confirmRegistrationInvestor,
  resendCode as resendCodeInvestor,
} from '@/api/users';
import { getDownloadUrl } from '@/api/documents';
import i18n from '@/plugins/i18n';

function defaultState() {
  return {
    navigation: {
      steps: [
        'Info',
        'Create',
        'Confirm',
      ],
      index: 0,
    },
    inception: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      tos: false,
    },
    isFormValid: true,
    downloadUrl: null,
  };
}

const state = defaultState();

const getters = {
  getField,
  steps: (state) => state.navigation.steps,
  index: (state) => state.navigation.index,
  component: (state) => state.navigation.steps[state.navigation.index],
  isFormValid: (state) => state.isFormValid,
  email: (state) => state.inception.email,
  downloadUrl: (state) => state.downloadUrl,
};

const mutations = {
  updateField,
  reset(state) {
    Object.assign(state, defaultState());
  },
  create(state, payload) {
    state.inception = {
      ...state.inception,
      ...payload,
    };
  },
  confirmed(state) {
    // remove personal data used for registration
    // from the store
    // keep only the date of ToS
    state.navigation = {
      ...state.navigation,
      ...{ index: 0 },
    };
    state.inception = {
      ...state.inception,
      ...{ password: '' },
    };
  },
  nextStep(state) {
    if (state.navigation.index !== state.navigation.steps.length) state.navigation.index += 1;
  },
  previousStep(state) {
    if (state.navigation.index > 0) state.navigation.index -= 1;
  },
  setStep(state, payload) {
    state.navigation.index = payload;
  },
  setEmail(state, payload) {
    state.inception.email = payload;
  },
  setFormValidity(state, payload) {
    state.isFormValid = payload;
  },
  setDownloadUrl(state, payload) {
    state.downloadUrl = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  async create({ commit }, registration) {
    // tos should be stored as the date of acceptance
    try {
      await registerInvestor({
        firstName: registration.firstName,
        lastName: registration.lastName,
        email: registration.email,
        password: registration.password,
      });
      commit('create', registration);
    } catch (error) {
      if (error.code === 'UserstatusUnconfirmedException') {
        commit('nextStep');
        throw error.code;
      }
      throw new Error(i18n.t(`registration.cognitoMessages.${error.code}`));
    }
  },
  async confirm({ state, commit }, code) {
    const { email } = state.inception;
    try {
      await confirmRegistrationInvestor({
        email,
        code,
      });
      commit('confirmed');
    } catch (error) {
      throw new Error(i18n.t(`registration.cognitoMessages.${error.code}`));
    }
  },
  async resendCode({ state }) {
    const { email } = state.inception;
    try {
      await resendCodeInvestor(email);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async setDownloadUrl({ commit }) {
    try {
      const input = {
        documentTemplateId: 'signin',
        documentKey: 'privacy_policy.pdf',
      };
      const response = await getDownloadUrl(input);
      commit('setDownloadUrl', response.pdfInBase64);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  nextStep({ commit }) {
    commit('nextStep');
  },
  previousStep({ commit }) {
    commit('previousStep');
  },
  setStep({ commit }, payload) {
    commit('setStep', payload);
  },
  setEmail({ commit }, payload) {
    commit('setEmail', payload);
  },
  setFormValidity({ commit }, payload) {
    commit('setFormValidity', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
