String.prototype.humanize = function () {
  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  const words = this.match(/[A-Za-z][a-z]*/g) || [];
  return words.map(capitalize).join(' ');
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.substring(1);
};

String.prototype.decapitalize = function () {
  return this.charAt(0).toLowerCase() + this.substring(1);
};
