function defaultState() {
  return {
    investorClassification: {
      navigation: {
        steps: [
          'Acknowledgement',
          'Conditions',
          'InvestorClassification',
          'Overview',
        ],
        index: 0,
      },
      classification: {
        acknowledgement: {
          optInForProfessional: false,
        },
        conditions: {
          wealthOver500K: false,
          workExperienceFinancialSector: false,
          profession: null,
        },
        investorClassification: {
          isProfessionalForAllSecurities: false,
          professionalSecurities: [],
        },
      },
      loading: false,
    },
  };
}
const state = defaultState();

const getters = {
  investorClassificationSteps: (state) => state.investorClassification.navigation.steps,
  investorClassificationIndex: (state) => state.investorClassification.navigation.index,
  investorClassificationStep(state) {
    return state.investorClassification.navigation.steps[state.investorClassification.navigation.index];
  },
  classification: (state) => state.investorClassification.classification,
  investorClassificationLoading: (state) => state.investorClassification.loading,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  nextInvestorClassificationStep(state) {
    if (state.investorClassification.navigation.index !== state.investorClassification.navigation.steps.length) {
      state.investorClassification.navigation.index += 1;
    }
  },
  previousInvestorClassificationStep(state) {
    if (state.investorClassification.navigation.index > 0) state.investorClassification.navigation.index -= 1;
  },
  setClassificationAcknowledgement(state, payload) {
    state.investorClassification.classification.acknowledgement = payload;
  },
  setClassificationConditions(state, payload) {
    state.investorClassification.classification.conditions = payload;
  },
  setClassificationInvestorClassification(state, payload) {
    state.investorClassification.classification.investorClassification = payload;
  },
  setInvestorClassificationLoading(state, payload) {
    state.investorClassification.loading = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  nextInvestorClassificationStep({ commit }) {
    commit('nextInvestorClassificationStep');
  },
  previousInvestorClassificationStep({ commit }) {
    commit('previousInvestorClassificationStep');
  },
  setClassificationAcknowledgement({ commit }, payload) {
    commit('setClassificationAcknowledgement', payload);
  },
  setClassificationConditions({ commit }, payload) {
    commit('setClassificationConditions', payload);
  },
  setClassificationInvestorClassification({ commit }, payload) {
    commit('setClassificationInvestorClassification', payload);
  },
  setInvestorClassificationLoading({ commit }, payload) {
    commit('setInvestorClassificationLoading', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
