export default {
  sections: {
    personal: {
      title: 'Personal Information',
      steps: {
        RegistrationProcessOverview: 'Overview',
        PersonalData: 'Personal Information',
        GeneralInformation: 'General Information',
        ContactData: 'Address Information',
        IdentificationData: 'Identification Information',
        TaxInformation: 'Tax Information',
        BankAccountInformation: 'Bank Account',
        SourceOfWealthInformation: 'Source Of Funds',
        Overview: 'Data Overview',
      },
    },
    classification: {
      title: 'Investor Classification',
      steps: {
        RegistrationProcessOverview: 'Overview',
        Acknowledgement: 'Request',
        Conditions: 'Criteria',
        InvestorClassification: 'Classification',
        Overview: 'Approval',
      },
    },
    investmentSurvey: {
      title: 'Knowledge & Experiences',
      steps: {
        RegistrationProcessOverview: 'Overview',
        Intro: 'Introduction',
        Products: 'Products',
        Services: 'Services',
        Overview: 'Confirmation',
      },
    },
    videoIdent: {
      title: 'Video Identification',
      steps: {
        RegistrationProcessOverview: 'Overview',
        Information: 'Account Security',
        Selection: 'Video Identification',
      },
    },
    contractClosing: {
      title: 'Contract Signature',
      steps: {
        Overview: 'Overview',
      },
    },
    investorApproval: {
      title: 'Investor Approval',
      steps: {
        VideoIdent: 'Video Identification',
        MinimumDeposit: 'Minimum Deposit',
      },
    },
  },
  modal: {
    desc: 'Schließe jetzt Deine Registrierung ab, um sofort alle Funktionen von COMETUM nutzen zu können.',
    next: 'Registrierung abschließen',
    cancel: 'Abbrechen',
  },
  navButtons: {
    back: 'Back',
    next: 'Next',
    nextLater: 'Continue and finish later',
    becomePro: 'BECOME A PROFESSIONAL CLIENT NOW',
    finish: `Let's Go!`,
  },
  generalInformation: {
    desc: 'Please note that COMETUM currently only accepts private investors and not legal entities',
    customerType: `I hereby declare that I am using COMETUM's platform for private investment purposes`,
  },
  personalData: {
    desc: 'We need some personal information to create your account',
    fields: {
      gender: 'Gender',
      firstName: 'First Name',
      lastName: 'Last Name',
      educationDegree: 'Highest Degree of Education',
    },
    error: 'For technical reasons, a query is currently not possible. Our team has been informed and is already working on a quick solution. Please try again later.',
  },
  contactData: {
    desc: 'Please provide your registration address',
    fields: {
      countryResidence: 'Country',
      street: 'Street',
      houseNo: 'House Number',
      zip: 'Postal Code',
      city: 'City',
      mobile: 'Mobile Phone Number',
      mailbox: 'Mailbox',
      phone: 'Phone Number',
      email: 'Email',
    },
  },
  identificationData: {
    desc: 'Please provide the information on the date and place of your birth',
    fields: {
      birthDay: 'Birthdate',
      birthPlace: 'Place of Birth',
      countryBirth: 'Country of Birth',
      isUsCitizen: 'I am not a US citizen and taxable in either the US or Canada.',
      isPoliticallyExposed: 'I am not a politically exposed person (PEP).',
      countryPrimCitizen: 'Country of Citizenship',
    },
    tooltipMessage: 'Eine politisch exponierte Person (PEP) ist ein/e Politiker/in oder eine Person in deren unmittelbarem Umfeld. Nach den Vorschriften zur Bekämpfung von Geldwäsche oder Terrorismusfinanzierung dürfen wir aktuell solche Personen nicht als Kunde aufnehmen. Du kannst die Anmeldung jederzeit durch einen Klick auf den Schließen-Button abbrechen.',
  },
  taxInformation: {
    descOne: 'Please share with us some information related to taxes',
    descTwo: 'If you are taxable in more than one country, start with your primary residence.',
    addBtn: 'I am taxable in another country',
    fields: {
      countryTaxation: 'I am a taxpayer in',
      taxNo: 'Tax Number',
      errorMsg: 'The tax identification number provided does not appear to be correct.',
    },
  },
  bankAccountInformation: {
    title: 'Please specify you reference bank account.',
    desc: 'Please provide details about your preferred reference bank account',
    fields: {
      refBankAccOwner: 'Bank Account Owner',
      refBankIban: 'IBAN',
      refBankName: 'Reference Bank',
      refBankBic: 'BIC',
    },
  },
  sourceOfWealthInformation: {
    desc: 'Please provide information about your primary source of funds',
    fields: {
      sourceOfWealth: 'Primary Source of Funds',
    },
  },
  dataOverview: {
    desc: 'Please, verify that your data is correct',
    subTitles: {
      personalData: 'Personal Information',
      contactData: 'Address Information',
      identificationData: 'Birthdate Information',
      financialData: 'Financial Information',
    },
  },
  registrationProcessOverview: {
    title: 'Our Registration Process',
    steps: {
      account: 'Create an Account',
      personal: 'Personal Data',
      classification: 'Investor Classification',
      investmentSurvey: 'Knowledge & Experiences',
      videoIdent: 'Video Identification',
      contractClosing: 'Contract Signature',
      investorApproval: 'Investor Approval',
    },
    personal: {
      title: 'Personal Information',
      desc: 'We are pleased that you have chosen digital private banking from COMETUM. To complete your registration, we need some personal information from you. This should not take longer than 10 minutes. Afterwards you have to identify yourself via VideoIdent. We are obliged to do this due to the Money Laundering Act (GWG).<br/><br/>Happy Investing!<br/><br/>Your COMETUM Team',
    },
    classification: {
      title: 'Investor Classification',
      desc: 'In the next two steps, we need a few pieces of information to create your investor profile.<br/><br/>We can use your answers to determine whether you are a retail investor or a professional retail investor and adjust our product offering accordingly.<br/><br/>As a professional investor according to Annex II of Directive 2014/65/EU (MiFid II), you can be assessed if you meet both of the following criteria.',
    },
    investmentSurvey: {
      title: 'Knowledge & Experiences',
      desc: 'The following questions will help us better assess your knowledge & experience of financial investment products and create an investor profile for you.',
    },
    videoIdent: {
      title: 'Video Identification',
      desc: `Congratulations! You've almost made it.<br/><br/>Finally, your personal details just need to be verified using the VideoIdent process from our partner, IDnow.<br/><br/>Please follow the instructions to do so.`,
    },
    contractClosing: {
      title: 'Contract Signature',
      desc: 'Please approve the following contracts in order to start investing.',
    },
    investorApproval: {
      title: 'Investor Approval',
      desc: 'Once you ID Now process has completed and your minimum deposit has arrived, you can start investing.',
    },
    completed: {
      title: 'Sign up completed!',
      desc: 'You have done it. We are happy to finally welcome you as a customer.<br/><br/>See the Marketplace section to make your first investment with COMETUM.<br/><br/>If you have any questions, the COMETUM team will be happy to help.',
    },
  },
  classification: {
    acknowledgement: {
      question: 'Wenn Du gewisse Bedingungen erfüllst, hast Du die Möglichkeit, Dich als Professioneller Kunde einstufen zu lassen.<br/><br/>Hier kannst Du im Detail sehen, was der Unterschied zwischen einem Privatkunden und einem Professionellen Kunden ist und was dies für Dein Schutzniveau und Deine Anlagefreiheit bedeutet.',
      answer: {
        yes: 'Ja, ich möchte als professioneller Kunde eingestuft werden.',
        no: 'Nein, ich geniesse lieber die umfangreichen Schutzniveaus welche die gesetzlichen Regelungen für Privatkunden vorsehen und verzichte auf eventuelle Vorteile.',
      },
    },
    conditions: {
      intro: 'Gemäß den Vorschriften des Wertpapierhandelsgesetzes (WpHG) sind Wertpapierdienstleistungsunternehmen (WpDU) verpflichtet, bei einer mögliche Kundeneinstufung als "professionellen Privatkunden" intensiv zu prüfen. Hierzu bitten wir Dich zunächst um Beantwortung der nachfolgenden Fragen:',
      conditions: {
        wealthOver500K: {
          question: 'Wie hoch schätzt Du dein Vermögen (Bankguthaben und Wertpapiervermögen)?',
          answer: {
            yes: '> 500.000 €',
            no: '< 500.000 €',
          },
        },
        workExperienceFinancialSector: {
          question: 'Hast Du für mindestens ein Jahr einen Beruf am Kapitalmarkt ausgeübt?',
          answer: {
            yes: 'Ja',
            no: 'Nein',
          },
        },
        profession: {
          title: 'Meine Tätigkeit erforderte Kenntnisse über die in Betracht kommenden Geschäfte und Wertpapierdienstleistungen voraussetzt. (z.B. Börsenmakler, Bankkaufmann oder Vermögensverwalter)<br/>Falls Ja, Beruf:',
          placeholder: 'Beruf',
        },
      },
      modalDescription: {
        pro: 'Aufgrund Deiner Angaben besteht für Dich die Möglichkeit, Dich als Professioneller Kunde einstufen zu lassen.',
        private: 'Gemäß den Vorschriften des Wertpapierhandelsgesetzes (WpHG) erfüllst Du leider noch nicht alle Kriterien. Natürlich kann sich das in der Zukunft ändern. Wir fragen Dich in Zukunft regelmäßig wieder danach. Bis dahin genießt Du das Schutzniveau eines Privatanlegers.',
      },
    },
    investorClassification: {
      intro: 'Hinsichtlich welcher Geschäftsart/en wünschst Du eine Einstuftung als Professioneller Kunde?',
      radio: {
        all: 'Für alle Geschäftsarten auf COMETUM.',
        choose: 'Nur für Geschäftsarten, für die ich auf Basis meiner Angaben qualifiziert bin.',
      },
      modal: {
        text: 'Wähle die Option “Für alle Geschäftsarten auf COMETUM”, um eine Einstufung als Professioneller Kunde für alle Assets und Geschäftsarten vorzunehmen, in welche Du auf COMETUM zum jetzigen Zeitpunkt investieren kannst.',
        button: 'Okay',
      },
      notify: 'Du wurdest erfolgreich als Professioneller Kunde eingestuft.',
      skip: {
        title: 'Abbrechen und weiter als Privatkunde',
        modal: {
          question: 'Bist Du sicher, dass Du die Einstufung als Professioneller Kunde abbrechen willst und als Privatkunde fortfahren möchtest?',
          answer: {
            yes: 'Ja',
            no: 'Nein',
          },
        },
      },
    },
    documents: {
      title: 'Möchtest Du künftig als Professioneller Kunde eingestuft werden?',
      description: 'Für diese Geschäftsart/en beantragst Du eine Einstufung als Professioneller Kunde:',
      intro: 'Als Professioneller Kunde hast Du neben uneingeschränkten Anlagemöglichkeiten ein limitiertes Schutzniveau.',
      introDocuments: 'Weitere Informationen findest Du in den folgenden Dokumenten.',
      footer: 'Mit dem Klick auf den Button "JETZT PROFESSIONELLER KUNDE WERDEN" wird Dein Antrag auf Einstufung als Professioneller Kunde verbindlich.',
    },
  },
  investmentSurvey: {
    views: {
      intro: {
        intro: 'The following questions will help us to better assess your knowledge & experience regarding the following financial investment products.',
        outro: 'Your answers will help us evaluate whether the financial investment products we offer fit your investor profile.',
      },
      products: {
        title: 'Investment products',
        knowledgeQuestion: (product) => `Do you already have knowledge related to ${product.named('product')}?`,
        experienceQuestion: (product) => `How would you rate your experience with ${product.named('product')}?`,
      },
      services: {
        title: 'Investment services',
        experienceQuestion: 'How would you rate your experience with investment services?',
      },
      overview: {
        title: 'Deine Kenntnisse und Erfahrungen auf einen Blick',
        intro: 'Hier findest Du Deine bisher gemachten Angaben. Du kannst jetzt und auch später Änderungen vornehmen.',
        status: 'Kenntnisse',
        edit: 'Änderung',
      },
    },
    components: {
      skip: {
        trigger: 'Skip aptitude test',
        title: 'Abstention from providing information about knowledge and experience',
        confirmation: 'I hereby confirm that I want to skip the aptitude test and that I do not want to provide information about my knowledge and experience',
        cancel: 'Cancel',
        next: 'Continue',
      },
    },
    products: {
      InvestmentFund: 'Investment Funds',
      GovernmentBonds: 'Government Bonds',
      Bond: 'Bonds',
      Share: 'Stocks',
      CryptoCurrency: 'Crypto Currencies / Security Tokens',
      UnsecuredJuniorDebt: 'Unsecured Junior Debt',
    },
    services: {
      independent: 'Independent / free of consulting',
      investmentConsultancy: 'With investment advice',
      investmentManagement: 'With asset management',
      avgYearlyTransactions: 'How many transactions do you do on average per year',
      avgTransactionValue: 'What is your average investment volume per transaction?',
    },
  },
  videoIdentInformation: {
    desc: 'Please read the following carefully and finish the verification with our partner',
    accountSecurity: {
      title: 'Account Security',
      desc: 'Here are a few important notes to keep your account safe: Do not verify on behalf of anyone but yourself. Do not share your personal information or login details with anyone. If you are unsure about the procedure, please contact our support team. They will be happy to help you.',
    },
    biometricData: {
      title: 'Information on biometric data',
      desc: 'With this identification method, we also process biometric data. These are deleted immediately after verification.',
    },
    checkbox: {
      responsibility: 'I hereby confirm that I have not been contacted or instructed by any third person to perform this verification, and I am signing up at COMETUM by my own choice in order to buy and sell digital assets.',
      processing: 'I hereby agree to processing my biometric data for the purpose of identification by means of this video identification procedure via the COMETUM GmbH. This consent cannot be revoked.',
    },
  },
  videoIdent: {
    title: 'Complete video identification with our partners IDnow and Bankhaus von der Heydt.',
    desc: 'Please select one of the follow options to do a Video Ident',
    browser: {
      title: 'Browser',
      desc: 'To start Video Ident with your browser, please click the button below',
      button: 'Start Video Ident',
    },
    mobileApp: {
      title: 'Mobile App',
      desc1: 'Download the IDnow Mobile Client via the app store',
      desc2: 'Launch the app and start identification with your Ident-ID',
      ccNotification: 'Successfully copied your Ident-ID to clipboard',
      subtitle: 'Checklist für die Videoidentifizierung per App',
      list: {
        1: 'Halte Deinen <span class="defaultText--text">Personalausweis</span> oder Deinen Reisepass bereit',
        2: 'Halte ein aktuelles <span class="defaultText--text">Dokument, mit dem Deine Adresse verifiziert werden kann</span> (z.B. Stromrechnung, Internetrechnung, Meldebescheinigung oder Kontoauszug), bereit',
        3: 'Starte die <span class="defaultText--text">IDnow Ident App</span> auf Deinem Smartphone',
        4: 'Gib die <span class="defaultText--text">Ident-ID</span> für die Videoidentifizierung in der App an',
        5: 'Bestätige bei IDnow, dass Du Dich für das <span class="defaultText--text">Bankhaus von der Heydt</span> verifizierst',
        6: 'Nach erfolgreicher Verifizierung leiten wir Dich von hier <span class="defaultText--text">automatisch zum nächsten Schritt</span>',
      },
    },
    triggerKycStatusChange: {
      info: 'Your are currently on our Test System! If you completed the video ident, please click the button below!',
      button: 'Trigger Kyc Status Change',
    },
    error: 'Unfortunately IDnow is experiencing errors, please try again later',
  },
  investorApproval: {
    title: 'Willkommen bei COMETUM!',
    desc: (info) => `Damit Du Dein erstes Investment tätigen kannst, zahle auf Deinen COMETUM-Account eine Mindesteinlage von ${info.named('minimumDeposit')} ein.<br>Auf Deinem COMETUM-Account befinden sich aktuell ${info.named('cashBalance')}.`,
    subtitle: 'Geld einzahlen',
    subDescription: 'Die folgenden Schritte erklären, wie Du auf Deinen COMETUM-Account Geld einzahlen kannst.',
    listItemOne: 'Unten in der Karte findest Du eine IBAN und eine Payment-ID.',
    listItemTwo: 'Um Geld einzuzahlen, überweise einen Betrag an dieses Konto und gib in der Überweisung die Payment-ID als Verwendungszweck an.',
    cardSubtitle: 'COMETUM-ID 92749821',
    cardItemOne: 'IBAN',
    cardItemTwo: 'Payment-ID',
    copy: 'Copied to clipboard',
    triggerMinimumDeposit: {
      info: 'Your are currently on our Test System! Please click the button below to deposit €150k of real fake money!',
      button: 'Trigger Deposit',
    },
    error: 'We are having trouble to query your account, we are already working on solving the issue. Please come back at a later time.',
  },
  popup: {
    title: 'Note',
    subtitle: 'You are in preview mode',
    desc: 'Complete the onboarding now to benefit from all COMETUM features!',
    close: 'Cancel',
    next: 'Complete Onboarding',
  },
  complete: {
    key: 'Document template with key',
    missing: 'is missing!',
  },
  contractClosing: {
    title: 'Überprüfe Deinen Auftrag',
    subtitle: 'Im Rahmen der Nutzung der COMETUM Plattform, schließt Du verschiedene Verträge mit unseren Partnern, dem Bankhaus von der Heydt GmbH & Co. KG und der Effecta GmbH.',
    description: 'Wir haben Deine Daten übernommen und die Vertragsunterlagen nachfolgend für Dich aufgelistet. Erst mit dem abschließenden Klick “Jetzt COMETUM Konto eröffnen” werden die Vertragsunterlagen an uns übermittelt.',
    subDescription: 'Bitte lies Dir alle Vertragsunterlagen sorgfältig durch und nimm diese zur Kenntnis.',
    footer: 'Im Anschluss erhältst Du von uns eine Bestätigungs-Email, mit der Möglichkeit sämtliche Vertragsdokumente einzusehen und herunterzuladen.',
  },
  cometum: {
    title: 'COMETUM: Kundenvereinbarung ',
    description: 'Im Folgenden findest Du die COMETUM-Kundenvereinbarung zur Nutzung unserer Plattform.',
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich handle auf eigene Rechnung und im eigenen Namen.</li><li>Ich habe die COMETUM Kundenvereinbarung (samt Widerrufsbelehrung) und das Dokument “Risiken der Kapitalanlage, Preis- und Leistungsverzeichnis” erhalten.</li><li>Ich beantrage den Abschluss des Kundenvertrags mit der COMETUM GmbH zu den Vertragsbedingungen.</li><li>Ich bin damit einverstanden, dass die COMETUM GmbH unverzüglich nach Beendigung des Nutzungsvertrages alle meine Daten innerhalb des COMETUM IT-Systems an den Emittenten des Investment-Tokens weitergeben wird. Die Erteilung dieser Einwilligung ist freiwillig. Ich kann sie jederzeit mit Wirkung für die Zukunft widerrufen. Allerdings ist mir bewusst, dass in diesem Fall keine Weitergabe meiner Daten an den Emittenten erfolgen wird. Weitere Einzelheiten finden sich in der Datenschutzerklärung von COMETUM.</li></ul>',
    customer_agreement: {
      pdf: {
        title: 'COMETUM AGB',
      },
    },
    risks: {
      pdf: {
        title: 'Risiken der Kapitalanlage',
      },
    },
  },
  effecta: {
    title: 'Effecta: Kundeninformationen',
    description: 'COMETUM wird als vertraglich gebundener Vermittler bei der Vermittlung von Finanzinstrumenten ausschließlich im Namen, auf Rechnung und unter Haftung des Wertpapierinstituts Effecta GmbH tätig. Deswegen solltest Du vor Abschluss Deiner Investition die Kundeninformationen der Effecta GmbH zur Kenntnis nehmen.',
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich habe die “Effecta Kundeninformationen” erhalten und von ihrem Inhalt Kenntnis genommen.</li><li>Ich stimme der Verarbeitung und Übertragung meiner Daten, im Zuge des Vertragsschlusses, an das Haftungsdach der COMETUM GmbH, dem unabhängigen Finanzdienstleistungsinstitut Effecta GmbH Florstadt, zu.</li></ul>',
    customer_information: {
      pdf: {
        title: 'Effecta - Kundeninformationen',
      },
    },
  },
  bvdh: {
    title: 'Bankhaus von der Heydt: Vertragsdokumente',
    description: 'Um bei COMETUM investieren zu können, ist es notwendig ein Konto mit Krypto-Wallet bei unserem Partner zu eröffnen, dem Bankhaus von der Heydt.',
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich handele im eigenen Namen und auf eigene Rechnung.</li><li>Ich bin mit dem Vertragsgegenstand einverstanden.</li><li>Ich stimme den Vertragsbedingungen für ein Krypto-Wallet mit Verrechnungskonto zu.</li><li>Ich habe folgende Dokumente erhalten: Bank AGB, Preis- und Leistungsverzeichnis ("PLV"), Verwahrbedingungen und Verbraucherinformationen inkl. Widerrufsrecht.</li><li>Ich möchte ein Konto/Wallet bei dem Bankhaus von der Heydt eröffnen.</li></ul>',
    bank_terms_and_conditions: {
      pdf: {
        title: 'Bankhaus von der Heydt - Bank AGB',
      },
    },
    custody: {
      pdf: {
        title: 'Bankhaus von der Heydt - Verwahrbedingungen',
      },
    },
    customer_information: {
      pdf: {
        title: 'Bankhaus von der Heydt - Verbraucherinformationen inkl. Widerrufsbelehrung',
      },
    },
    price_sheet: {
      pdf: {
        title: 'Bankhaus von der Heydt - Preis- Leistungsverzeichnis',
      },
    },
  },
  general: {
    title: 'Ihre Bestätigung ist erforderlich',
    description: '',
    checkboxText: 'Hiermit willige ich ein:<br><br><ol><li>Meine Angaben sind vollständig und korrekt.</li><li>Mit der Aufzeichnung von Telefongesprächen und elektronischer Kommunikation bin ich einverstanden.</li><li>Ich werde nachträglich eintretende Änderungen, der in diesem Formular gemachten Angaben, unverzüglich der COMETUM GmbH mitteilen und durch entsprechende Dokumente (Kopie des Ausweises etc.) nachweisen.</li></ol>',
    customer_information: {
      pdf: {
        title: 'Effecta - Kundeninformationen',
      },
    },
  },
  warning: 'Complete the registration now to be able to invest',
};
