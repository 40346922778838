export default {
  cards: {
    personal: {
      title: 'Account',
      data: 'Personal Data',
      security: 'Login & Security',
      KE: 'Knowledge & Experience',
      darkMode: 'Light Mode/ Dark Mode',
    },
    contact: {
      title: 'Announcements',
      notifications: 'Notifications',
    },
    documents: {
      title: 'Documents',
      billingContracts: 'Billing & Contracts',
      legal: 'Legal Information',
    },
    other: {
      title: 'Other',
      signout: 'Sign out',
      deleteAccount: 'Delete COMETUM Account',
    },
    notifications: {
      important: 'Important Notifications',
      useful: 'Useful Notifications',
    },
    comingSoon: 'Coming Soon',
  },
  views: {
    changePassword: {
      desc: 'Please choose a secure password (a minimum of 6 characters and at least one number).',
      form: {
        currentPassword: 'Old Password',
        newPassword: 'New Password',
        submitButton: 'Change',
      },
      success: 'Password changed successfully',
    },
    support: {
      email: 'E-Mail',
      phone: 'Phone Number',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
    },
    notifications: {
      subscribed: 'Successfully subscribed to {type} notifications',
      unsubscribed: 'Successfully unsubscribed from {type} notifications',
      important: {
        title: 'Important Notifications',
        push: {
          title: 'Push Notifications',
          subtitle: 'Receive push notifications on sales and important product information',
        },
        email: {
          title: 'Email',
          subtitle: 'Receive email notifications on sales and important product information',
        },
      },
      useful: {
        title: 'Useful Notifications',
        push: {
          title: 'Push Notifications',
          subtitle: 'Receive push notifications on interesting news and new assets',
        },
        email: {
          title: 'Email',
          subtitle: 'Receive email notifications on interesting news and new assets',
        },
        phone: {
          title: 'Phone Calls',
          subtitle: 'Receive phone calls notifications on interesting news and new assets',
        },
      },
    },
    legal: {
      privacyPolicy: 'Privacy Policy',
      notice: 'Legal Notice',
      terms: 'Terms of Service',
    },
    billingContracts: {
      empty: 'Later you will find here the information on your billing and contracts',
    },
    deleteAccount: {
      consequences: 'I understand the consequences',
      deleteButton: 'Delete My Account',
      title: `You're about to permanently delete your account`,
      subtitle: 'This action is irreversible',
      desc: `
        We are legally obliged to keep some of your data for 5 years.<br/>
        If you would like to know what data we will keep, drop us an email at <a href="mailto:legal@cometum.com" target="_blank">legal@cometum.com</a> and we will export it for you.
`,
    },
  },
};
