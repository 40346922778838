import i18n from '@/plugins/i18n';

export default [
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Salary'),
    value: 1,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Pension'),
    value: 2,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.CapitalIncome'),
    value: 3,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.RentalIncome'),
    value: 4,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Loans'),
    value: 5,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.InsuranceClaims'),
    value: 6,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Divorce'),
    value: 7,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Lottery'),
    value: 8,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Savings'),
    value: 9,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Heritage'),
    value: 10,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Gift'),
    value: 11,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Investments'),
    value: 12,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.LifeInsurance'),
    value: 13,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Companies'),
    value: 14,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Judgements'),
    value: 15,
  },
  {
    text: i18n.t('mappings.onboarding.sourcesOfWealth.Crypto'),
    value: 16,
  },
];
