import {
  all as getAssets,
  getImages,
  getDocuments,
} from '@/api/assets';

function defaultState() {
  return {
    assets: [],
    documents: [],
  };
}

const state = defaultState();

const getters = {
  all: (state) => state.assets,
  get: (state) => (id) => (state.assets.find((asset) => asset.id === id)),
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  assets(state, assets) {
    state.assets = assets;
  },
  edit(state, asset) {
    const index = state.assets.findIndex((item) => item.id === asset.id);
    state.assets.splice(index, 1, asset);
  },
  setImages(state, payload) {
    state.assets.images = payload;
  },
  setDocuments(state, payload) {
    state.assets.documents = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  async fetch({ commit }) {
    try {
      const assets = await getAssets();
      if (assets.length) commit('assets', assets);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async getImages({ commit }, input) {
    try {
      const images = await getImages(input);
      commit('setImages', images);
      return images;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async getDocuments({ commit }, input) {
    try {
      const documents = await getDocuments(input);
      commit('setDocuments', documents);
      return documents;
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
