import countries from 'i18n-iso-countries';
import i18n from '@/plugins/i18n';

const currentLocale = i18n.locale.split('-')[0];

const ewr = [
  'BE',
  'BG',
  'DK',
  'DE',
  'EE',
  'FI',
  'FR',
  'GR',
  'IE',
  'IT',
  'HR',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'AT',
  'PL',
  'PT',
  'RO',
  'SE',
  'SK',
  'SI',
  'ES',
  'CZ',
  'HU',
  'CY',
  'IS',
  'LI',
  'NO',
  'CH',
];

const allCountries = (locale = currentLocale) => (
  Object.entries(countries.getNames(locale))
    .map(([alpha2, name]) => ({
      alpha2,
      name,
      alpha3: countries.alpha2ToAlpha3(alpha2),
    }))
);

const ewrCountries = (locale = currentLocale) => allCountries(locale).filter((item) => ewr.includes(item.alpha2));

const germanyIndex = allCountries().findIndex((country) => country.alpha2 === 'DE');

const germany = allCountries()[germanyIndex];

export {
  allCountries,
  ewrCountries,
  germany,
};
