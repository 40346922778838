import i18n from '@/plugins/i18n';

export default [
  {
    text: i18n.t('mappings.onboarding.genders.male'),
    value: 'Male',
  },
  {
    text: i18n.t('mappings.onboarding.genders.female'),
    value: 'Female',
  },
];
