/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID) {
    getAsset(id: $id) {
      id
      assetStatus
      assetType
      assetSubType
      title
      subTitle
      description
      publishingDate
      fundingPercentage
      subscriptionInformation {
        emissionVolume
        tokenPrice
        startDate
        endDate
        termToMaturity
        termStartDate
        termEndDate
        historicalPerformance
        averageFees
        assetIssuer
        legalName
      }
    }
  }
`;

export const listInvestorAssets = /* GraphQL */ `
  query ListInvestorAssets {
    listInvestorAssets {
      id
      assetStatus
      assetType
      assetSubType
      fundingPercentage
      title
      subTitle
      description
      publishingDate
      subscriptionInformation {
        emissionVolume
        tokenPrice
        startDate
        endDate
        termToMaturity
        termStartDate
        termEndDate
        historicalPerformance
        averageFees
        assetIssuer
        legalName
      }
    }
  }
`;
export const getInvestor = /* GraphQL */ `
  query GetInvestor {
    getInvestor {
      id
      userRole
      customerId
      email
      mfaStatus
      nextStage {
        nextMainStage
        nextSubStage
      }
      personalData {
        gender
        firstName
        lastName
        educationDegree
      }
      generalInformation {
        customerType
        purposeOfBusiness
        extCustomerId
      }
      identificationData {
        birthDay
        birthPlace
        countryBirth
        countryPrimCitizen
        isUsCitizen
        isPoliticallyExposed
      }
      contactData {
        countryResidence
        street
        houseNo
        zip
        city
        email
        phone
        mobile
        mailbox
      }
      financialData {
        taxNo
        countryTaxation
        taxNo2
        countryTaxation2
        taxNo3
        countryTaxation3
        taxNo4
        countryTaxation4
        taxNo5
        countryTaxation5
        refBankIban
        refBankBic
        refBankAccOwner
        sourceOfWealth
      }
      videoIdentData {
        status
        result
        redirect
      }
      classification {
        conditions {
          wealthOver500K
          workExperienceFinancialSector
        }
        investorClassification {
          investorClassification
          professionalSecurities
        }
      }
      investmentSurvey {
        skipSurvey
        investmentExperience {
          basic {
            type
            knowledge
            experience
          }
          expanded {
            type
            knowledge
            experience
          }
          extensiveOrSpecial {
            type
            knowledge
            experience
          }
        }
        serviceExperience {
          independent
          investmentConsultancy
          investmentManagement
        }
        avgYearlyTransactions
        avgTransactionValue
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const getPortfolio = /* GraphQL */ `
  query GetInvestorPortfolio {
    getPortfolio {
      id
      assetStatus
      assetType
      fundingPercentage
      title
      totalSubscriptionAmount
      emissionVolume
      imageUrl
    }
}
`;
export const getDocumentTemplate = /* GraphQL */ `
  query GetDocumentTemplate($input: GetDocumentTemplateInput) {
    getDocumentTemplate(input: $input) {
      key
      accessLevel
      category
      title
      description
      checkboxText
      documents {
        title
        key
        url
      }
    }
  }
`;
export const downloadUnsignedDocument = /* GraphQL */ `
  query DownloadUnsignedDocument($input: DownloadUnsignedDocumentInput) {
    downloadUnsignedDocument(input: $input) {
      pdfInBase64
    }
  }
`;
export const downloadSignedDocument = /* GraphQL */ `
  query DownloadSignedDocument($input: DownloadSignedDocumentInput) {
    downloadSignedDocument(input: $input) {
      pdfInBase64
    }
  }
`;
export const downloadDocumentTemplate = /* GraphQL */ `
  query DownloadDocumentTemplate($input: DownloadDocumentTemplateInput) {
    downloadDocumentTemplate(input: $input) {
      pdfInBase64
    }
  }
`;
export const getInvestorBalance = /* GraphQL */ `
  query GetInvestorBalance {
    getBalance {
      cashBalance
      minimumDeposit
      spendableCashBalance
      totalSubscriptionAmount
      paymentId
    }
  }
`;
export const getInvestorSubscriptionInformation = /* GraphQL */ `
  query getInvestorSubscriptionInformation($assetId: ID) {
    getInvestorSubscriptionInformation(assetId: $assetId) {
      subscribedTokens
      minSubscriptionAmount
      spendableCashBalance
      totalOfferedTokens
      totalTokenSupply
      tokenPrice
      debitAmountFactor
      maxSubscribableTokens
      maxSubscriptionType
      investorClassification
      emissionType
      pdpNotification
    }
  }
`;
export const checkAdequacy = /* GraphQL */ `
  query CheckAdequacy($orderId: ID) {
    checkAdequacy(orderId: $orderId)
  }
`;
export const getSubscriptionFees = /* GraphQL */ `
  query GetSubscriptionFees($orderId: ID) {
    getSubscriptionFees(orderId: $orderId) {
      LZ
      main {
        KA
        KE
        KL
        KG
      }
      components {
        KA {
          KAA
          KAB
        }
        KE {
          KEA
          KEB
        }
        KL {
          KLA
          KLB
        }
        KG {
          KGA
          KGB
        }
      }
      subComponents {
        KEA {
          KEAA
          KEAB
        }
        KGB {
          KGBA
        }
      }
    }
}
`;
export const getSubscriptionDocuments = /* GraphQL */ `
  query GetSubscriptionDocuments($orderId: ID) {
    getSubscriptionDocuments(orderId: $orderId) {
      key,
      accessLevel,
      category,
      assetId,
      title,
      description,
      checkboxText
      documents {
        key,
        title,
        url
      }
    }
  }
`;
export const listAssetImage = /* GraphQL */ `
  query ListAssetImage($input: ListAssetImageInput) {
    listAssetImage(input: $input) {
        assetImages {
            key
            url
        }
    }
  }
`;
export const triggerPayout = /* GraphQL */ `
  query TriggerPayout($input: InputInvestorPayout) {
    triggerPayout(input: $input) {
      transactionId
      customerId
      recipient
      recipientIban
      recipientBic
      amount
      currency
      payer
      payerIban
      payerBic
      payerBank
      purpose
      type
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents {
    listEvents {
      investorId
      timestamp
      eventType
      documents {
        documentTemplateId
        documentKey
      }
      eventInfo {
        prokId
        assetId
        orderId
        assetIssuer
        legalName
        amount
      }
    }
  }
`;
export const getConfigurations = /* GraphQL */ `
  query GetConfigurations {
    getConfigurations {
      stage
    }
  }
`;
export const listDocumentTemplate = /* GraphQL */ `
  query ListDocumentTemplate($input: ListDocumentTemplateFilterInput) {
    listDocumentTemplate(input: $input) {
      documentTemplates {
        key,
        accessLevel,
        category,
        assetId,
        title,
        description,
        checkboxText,
        documents {
            key,
            title,
            url
        }
      }
    }
  }
`;
export const validateIban = /* GraphQL */ `
  query ValidateIban($refBankIban: String) {
    validateIban(refBankIban: $refBankIban) {
      bic
      name
    }
  }
`;
