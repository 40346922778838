const userData = {
  classification: {
    acknowledgement: {
      optInForProfessional: false,
    },
    conditions: {
      wealthOver500K: false,
      workExperienceFinancialSector: false,
      profession: null,
    },
    investorClassification: {
      isProfessionalForAllSecurities: false,
      professionalSecurities: [],
    },
  },
  investmentSurvey: {
    skipSurvey: false,
    investmentExperience: {
      basic: [
        {
          type: 'InvestmentFund',
          knowledge: null,
          experience: 'LowerThan3Years',
        },
        {
          type: 'GovernmentBonds',
          knowledge: null,
          experience: 'LowerThan3Years',
        },
      ],
      expanded: [
        {
          type: 'Bond',
          knowledge: null,
          experience: 'LowerThan3Years',
        },
        {
          type: 'Share',
          knowledge: null,
          experience: 'LowerThan3Years',
        },
      ],
      extensiveOrSpecial: [
        {
          type: 'CryptoCurrency',
          knowledge: null,
          experience: 'LowerThan3Years',
        },
        {
          type: 'UnsecuredJuniorDebt',
          knowledge: null,
          experience: 'LowerThan3Years',
        },
      ],
    },
    serviceExperience: {
      independent: 'LowerThan3Years',
      investmentConsultancy: 'LowerThan3Years',
      investmentManagement: 'LowerThan3Years',
    },
    avgYearlyTransactions: 'NoTrans',
    avgTransactionValue: 'Lower3000',
  },
  videoIdentData: {
    status: null,
    result: null,
    redirect: null,
  },
};

export default userData;
