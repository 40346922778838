import apiMakeRequest from '@/plugins/apiRequest/index';
import {
  getDocumentTemplate as getDocument,
  downloadUnsignedDocument as downloadDocument,
  downloadSignedDocument,
  downloadDocumentTemplate,
} from '@/graphqlts/queries';

const get = async (input) => {
  try {
    const {
      data: {
        getDocumentTemplate: document,
      },
    } = await apiMakeRequest(getDocument, { input });
    return document;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const getDownloadUrl = async (input) => {
  try {
    const {
      data: {
        downloadUnsignedDocument: url,
      },
    } = await apiMakeRequest(downloadDocument, { input });
    return url;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const getSignedDownloadUrl = async (input) => {
  try {
    const {
      data: {
        downloadSignedDocument: url,
      },
    } = await apiMakeRequest(downloadSignedDocument, { input });
    return url;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const getDocumentTemplateUrl = async (input) => {
  try {
    const {
      data: {
        downloadDocumentTemplate: url,
      },
    } = await apiMakeRequest(downloadDocumentTemplate, { input });
    return url;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

export {
  get,
  getDownloadUrl,
  getSignedDownloadUrl,
  getDocumentTemplateUrl,
};
