import store from '@/store';

const notification = (
  type: string,
  message: string,
  options: { hide: Number } = { hide: 10000 },
) => {
  const timeout = options.hide;
  return store.dispatch('application/notify', {
    type,
    message,
    timeout,
  });
};

export default {
  error(message: string, options: { hide: Number }) {
    notification('error', message, options);
  },

  success(message: string, options: { hide: Number }) {
    notification('success', message, options);
  },

  info(message: string, options: { hide: Number }) {
    notification('info', message, options);
  },
};
