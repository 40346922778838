/* eslint-disable no-extend-native */
/* eslint-disable func-names */

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};

Array.prototype.contains = function (array) {
  const arraySet = new Set(array);
  return this.some((element) => arraySet.has(element));
};
