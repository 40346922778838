/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const registerInvestor = /* GraphQL */ `
  mutation RegisterInvestor($input: RegisterUserInput) {
    registerInvestor(input: $input)
  }
`;
export const registerAdmin = /* GraphQL */ `
  mutation RegisterAdmin($input: RegisterUserInput) {
    registerAdmin(input: $input)
  }
`;
export const confirmRegistration = /* GraphQL */ `
  mutation ConfirmRegistration($input: ConfirmRegistrationInput) {
    confirmRegistration(input: $input)
  }
`;
export const resendCode = /* GraphQL */ `
  mutation UserResendCode($email: String) {
    resendCode(email: $email) {
      CodeDeliveryDetails {
        Destination,
        DeliveryMedium,
        AttributeName
      }
    }
  }
`;
export const login = /* GraphQL */ `
  mutation Login($input: LoginInput) {
    login(input: $input) {
      token
      access
      refresh
      onboardingStage {
        nextMainStage
        nextSubStage
      }
    }
  }
`;
export const refreshSession = /* GraphQL */ `
  mutation UserRefreshSession($input: RefreshSessionInput) {
    refreshSession(input: $input) {
      token,
      access,
      refresh
    }
  }
`;
export const forgotPasswordInit = /* GraphQL */ `
  mutation UserForgotPasswordInit($email: String) {
    forgotPasswordInit(email: $email) {
      CodeDeliveryDetails {
        Destination
        DeliveryMedium
        AttributeName
      }
    }
  }
`;
export const forgotPasswordComplete = /* GraphQL */ `
  mutation UserForgotPasswordComplete($input: ForgotPasswordCompleteInput) {
    forgotPasswordComplete(input: $input)
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset($input: InputAsset) {
    createAsset(input: $input)
  }
`;
export const updateCurrentInvestor = /* GraphQL */ `
  mutation UpdateCurrentInvestor($input: CurrentInvestorUpdate) {
    updateCurrentInvestor(input: $input) {
      nextStage {
        nextMainStage
        nextSubStage
      }
      status
    }
  }
`;
export const updateDocumentTemplate = /* GraphQL */ `
  mutation UpdateDocumentTemplate($input: UpdateDocumentTemplateInput) {
    updateDocumentTemplate(input: $input) {
      key
      uploadURL
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteCognitoUser {
    deleteCognitoUser
  }
`;
export const initSubscriptionOrder = /* GraphQL */ `
  mutation InitSubscriptionOrder($assetId: ID) {
    initSubscriptionOrder(assetId: $assetId) {
      orderId
    }
  }
`;
export const confirmInvestmentSurvey = /* GraphQL */ `
  mutation ConfirmInvestmentSurvey($orderId: ID) {
    confirmInvestmentSurvey(orderId: $orderId)
  }
`;
export const requestSubscriptionTokenAmount = /* GraphQL */ `
  mutation RequestSubscriptionTokenAmount($input: InputRequestSubscriptionTokenAmount) {
    requestSubscriptionTokenAmount(input: $input) {
      investorId
      orderId
      assetId
      orderStatus
      selfDisclosure
      confirmedInadequacy
      requestedNumberOfTokens
      subscriptionDateTime
      debitAmount
      createdAt
      updatedAt
    }
  }
`;
export const confirmInadequacy = /* GraphQL */ `
  mutation ConfirmInadequacy($orderId: ID) {
    confirmInadequacy(orderId: $orderId) {
      investorId
      orderId
      assetId
      orderStatus
      selfDisclosure
      confirmedInadequacy
      requestedNumberOfTokens
      subscriptionDateTime
      debitAmount
      createdAt
      updatedAt
    }
  }
`;
export const createSubscriptionOrder = /* GraphQL */ `
  mutation CreateSubscriptionOrder($orderId: ID) {
    createSubscriptionOrder(orderId: $orderId) {
      investorId
      orderId
      assetId
      orderStatus
      selfDisclosure
      confirmedInadequacy
      requestedNumberOfTokens
      subscriptionDateTime
      debitAmount
      subscriptionAmount
      createdAt
      updatedAt
    }
  }
`;
export const updateInvestmentSurvey = /* GraphQL */ `
  mutation UpdateInvestmentSurvey($input: UpdateInvestmentSurvey) {
    updateInvestmentSurvey(input: $input) {
      prokDowngrade
    }
  }
`;
export const updateInvestorClassification = /* GraphQL */ `
  mutation UpdateInvestorClassification($input: UpdateInvestorClassification) {
    updateInvestorClassification(input: $input) {
      status
    }
  }
`;
export const resetInvestorClassificationToPrivate = /* GraphQL */ `
  mutation ResetInvestorClassificationToPrivate {
    resetInvestorClassificationToPrivate {
      status
    }
  }
`;
export const triggerKycStatusChange = /* GraphQL */ `
  mutation TriggerKycStatusChange {
    triggerKycStatusChange {
      status
    }
  }
`;
export const triggerMinimumDeposit = /* GraphQL */ `
  mutation TriggerMinimumDeposit {
    triggerMinimumDeposit {
      status
    }
  }
`;
export const changePassword = /* GraphQL */ `
  mutation UserChangePassword($input: ChangePasswordInput) {
    changePassword(input: $input)
  }
`;
