import {
  forgotPasswordInit as forgotPasswordInitInvestor,
  forgotPasswordComplete as forgotPasswordCompleteInvestor,
} from '@/api/users';
import i18n from '@/plugins/i18n';

function defaultState() {
  return {
    data: {
      email: '',
      code: '',
      password: '',
    },
  };
}

const state = defaultState();

const getters = {
  data: (state) => state.data,
  email: (state) => state.data.email,
  code: (state) => state.data.code,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  update(state, payload) {
    state.data = {
      ...state.data,
      ...payload,
    };
  },
  confirmed(state) {
    // remove personal data used for registration
    // from the store
    // keep only the date of ToS
    state.email = '';
  },
  setEmail(state, payload) {
    state.data.email = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  async requestCode({ commit }, email) {
    // email is used as username
    try {
      await forgotPasswordInitInvestor(email);
      commit('update', { email });
    } catch (error) {
      // UserNotFoundException
      throw new Error(i18n.t(`forgotPassword.cognitoMessages.${error.code}`));
    }
  },
  async newPassword({ commit }, input) {
    try {
      await forgotPasswordCompleteInvestor(input);
      commit('reset');
    } catch (error) {
      switch (error.code) {
        case 'CodeMismatchException' || 'ExpiredCodeException':
          commit('update', { code: '' });
          throw new Error(i18n.t(`forgotPassword.cognitoMessages.${error.code}`));
        case 'UserNotFoundException':
          commit('reset');
          throw new Error(i18n.t(`forgotPassword.cognitoMessages.${error.code}`));
        default:
          commit('reset');
          throw new Error(i18n.t(`forgotPassword.cognitoMessages.GenericException`));
      }
    }
  },
  nextStep({ commit }) {
    commit('nextStep');
  },
  previousStep({ commit }) {
    commit('previousStep');
  },
  setEmail({ commit }, payload) {
    commit('setEmail', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
