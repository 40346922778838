import { getConfigurations as get } from '@/graphqlts/queries';
import apiMakeRequest from '@/plugins/apiRequest/index';

const getConfigurations = async () => {
  try {
    return await apiMakeRequest(get);
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

export {
  getConfigurations,
};
