// Amplify
import { Amplify } from 'aws-amplify';
import VueGtag from 'vue-gtag';
import Vue from 'vue';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import App from '@/App.vue';
import '@/plugins/string';
import '@/plugins/i18nCountries';
import router from '@/router';
import store from '@/store';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import notify from '@/utils/notify';
import eventBus from '@/plugins/eventBus';

// Amplify
/* eslint-disable-next-line */
import config from '@/aws-config';

Amplify.configure(config);
// Amplify

Vue.prototype.$bus = eventBus;
Vue.prototype.$notify = notify;
Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(VueGtag, {
  config: { id: 'G-YH9SZWNKSF' },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
