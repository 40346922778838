import store from '@/store';
import lazyLoad from '@/utils/lazyLoad';
import OnboardingRoutes from '@/router/private/onboarding';
import SettingsRoutes from '@/router/private/settings';

const privateRoutes = OnboardingRoutes.concat(SettingsRoutes);

const onboardingCheck = (from, next) => {
  if (!store.getters['user/onBoardingCompleted']) {
    store.dispatch('onboarding/setOnboardingModal', true);
    next({ name: from.name });
  }
  next();
};

const routes = [
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: lazyLoad('views/Portfolio/Index'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: lazyLoad('views/Profile/Index'),
    beforeEnter: (_, from, next) => {
      onboardingCheck(from, next);
    },
  },
  {
    path: '/profile/deposit',
    name: 'Deposit',
    component: lazyLoad('views/Profile/Deposit'),
    beforeEnter: (_, from, next) => {
      onboardingCheck(from, next);
    },
  },
  {
    path: '/profile/withdraw',
    name: 'Withdraw',
    component: lazyLoad('views/Profile/Withdraw/Index'),
    beforeEnter: (_, from, next) => {
      onboardingCheck(from, next);
    },
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    component: lazyLoad('views/Marketplace/Index'),
  },
  {
    path: '/marketplace/newdeals/:product',
    name: 'NewDealsProduct',
    component: lazyLoad('views/Marketplace/NewDeals/ProductDetailPage/Index'),
  },
  {
    path: '/subscription/:product',
    name: 'Subscription',
    component: lazyLoad('views/Subscription/Index'),
  },
  {
    path: '/marketplace/trade/:product',
    name: 'TradeProduct',
    component: lazyLoad('views/Marketplace/Trade/Product/Index'),
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: lazyLoad('views/Notifications/Index'),
  },
  {
    path: '/support',
    name: 'Support',
    component: lazyLoad('views/Support/Index'),
  },
  {
    path: '/logout',
    beforeEnter(to, from, next) {
      if (from.name !== 'Settings.KnowledgeExperience') {
        store.dispatch('auth/signout');
        store.dispatch('application/globalStateReset');
        next('/signin');
      }
    },
    name: 'Logout',
  },
].concat(privateRoutes);

export default routes.map((route) => {
  const meta = {
    public: false,
  };
  return { ...route, meta };
});
