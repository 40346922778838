import i18n from '@/plugins/i18n';

const knowledge = [
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.knowledge.yes'),
    value: true,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.knowledge.no'),
    value: false,
  },
];

const experiences = [
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.RangeOfExperience.LowerThan3Years'),
    value: 0,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.RangeOfExperience.Bet3To5Years'),
    value: 1,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.RangeOfExperience.GreaterThan5Years'),
    value: 2,
  },
];

const transactionNumber = [
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgYearlyTransactionsType.NoTrans'),
    value: 0,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgYearlyTransactionsType.Lower5Trans'),
    value: 1,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgYearlyTransactionsType.Bet5To10Trans'),
    value: 2,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgYearlyTransactionsType.MoreThan10Trans'),
    value: 3,
  },
];

const transactionVolume = [
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgTransactionValueType.Lower3000'),
    value: 0,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgTransactionValueType.Bet3000To5000'),
    value: 1,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgTransactionValueType.Bet5000To10000'),
    value: 2,
  },
  {
    text: i18n.t('mappings.onboarding.investmentSurvey.AvgTransactionValueType.Over10000'),
    value: 3,
  },
];

const mapFrontendValueToDbEnum = {
  rangeOfExperience: {
    0: 'LowerThan3Years',
    1: 'Bet3To5Years',
    2: 'GreaterThan5Years',
  },
  avgYearlyTransactions: {
    0: 'NoTrans',
    1: 'Lower5Trans',
    2: 'Bet5To10Trans',
    3: 'MoreThan10Trans',
  },
  avgTransactionValue: {
    0: 'Lower3000',
    1: 'Bet3000To5000',
    2: 'Bet5000To10000',
    3: 'Over10000',
  },
};

const mapDbEnumToFrontendValue = {
  rangeOfExperience: {
    LowerThan3Years: 0,
    Bet3To5Years: 1,
    GreaterThan5Years: 2,
  },
  avgYearlyTransactions: {
    NoTrans: 0,
    Lower5Trans: 1,
    Bet5To10Trans: 2,
    MoreThan10Trans: 3,
  },
  avgTransactionValue: {
    Lower3000: 0,
    Bet3000To5000: 1,
    Bet5000To10000: 2,
    Over10000: 3,
  },
};

export {
  knowledge,
  experiences,
  transactionNumber,
  transactionVolume,
  mapFrontendValueToDbEnum,
  mapDbEnumToFrontendValue,
};
