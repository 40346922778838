export default {
  experiences: {
    title: 'Has your knowledge and experience changed since your last subscription application?',
    subtitle: 'Overview of your knowledge and experience',
    abort: 'Zeichnungsprozess abgebrochen',
    modal: {
      desc: 'Willst Du Deine Kenntnisse & Erfahrungen anpassen und den Zeichnungsprozess verlassen?',
      yes: 'Yes, continue',
      no: 'Abort',
    },
  },
  navigation: {
    yes: 'Yes',
    no: 'No, continue',
    next: 'Next',
    back: 'Back',
    sign: 'Sign Binding',
  },
  amount: {
    title: 'How much would you like to invest?',
    invest: 'Already invested',
    min: 'Minimum subscription amount',
    max: 'Maximum credit limit',
    token: 'Available token',
    subscriptionAmount: 'Subscription amount',
    debitAmount: 'Debit amount including costs and allowances',
    tooltipMessage: 'Kosten und Zuwendungen inkl. Agio im ersten Jahr bezogen auf die von Dir angegebene Zeichnungssumme. Weitere Informationen im Zeichnungsprozess.',
    amountToken: 'Number of tokens',
    maxErrorBtn: (ctx) => `Select €${ctx.named('maxAmount')}`,
    checkbox: {
      title: 'Selbstauskunft',
      description1: 'Bei dieser Kapitalanlage ist die Höhe der Zeichnungssumme beschränkt und an bestimmte Voraussetzungen gebunden. Laut Wertpapierprospektgesetz sind wir dazu verpflichtet eine Selbstauskunft über Dein Vermögen oder Dein Einkommen von Dir einzuholen, wenn der Gesamtbetrag der Wertpapiere 1.000 € übersteigt. Eine Zeichnung zwischen 1.000 € und 10.000 € ist nur möglich, wenn Du mindestens eine der beiden folgenden Aussagen bestätigen kannst:',
      description2: 'Bei dieser Kapitalanlage ist die Höhe der Zeichnungssumme beschränkt und an bestimmte Voraussetzungen gebunden. Das Wertpapierprospektgesetz schreibt in diesem Fall vor, eine Selbstauskunft über Dein Einkommen einzuholen. Eine Zeichnung über 10.000 € ist nur möglich, wenn Du folgende Aussage bestätigen kannst:',
      text1: 'Ja, der Zeichnungsbetrag übersteigt zum Zeitpunkt der Zeichnung nicht den zweifachen Betrag meines durchschnittlichen monatlichen Nettoeinkommens. (Hinweis: Wenn Du mehrfach zeichnest, werden Einzelsummen addiert.)',
      text2: 'Ja, ich verfüge zum Zeitpunkt meiner Zeichnung über ein frei verfügbares Vermögen in Form von Bankguthaben und Finanzinstrumenten (Wertpapiere, Investmentfonds, Vermögensanlagen etc.) von mindestens 100.000 Euro.',
    },
  },
  adequacyCheck: {
    title: 'Unangemessenheit',
    text1: 'Gemäß Deiner Angaben im Fragebogen verfügst Du über zu wenig Kenntnisse und Erfahrungen, um die mit dem Erwerb des hier angebotenen Produkts verbundenen Risiken ausreichend beurteilen zu können. Das heißt, dass Deine Zeichnung nicht Deinem bisherigen Anlageprofil entspricht. Bitte informiere Dich noch einmal eingehend über die Ausgestaltung und Risikostruktur. Natürlich stehen wir Dir gerne für ein Gespräch zu Verfügung.',
    text2: 'Selbstverständlich kannst Du an Deiner Entscheidung auch weiterhin festhalten und das Produkt zeichnen. Hierfür bitten wir Dich, dies rechtskräftig zu bestätigen:',
    checkbox: 'Ich bin darüber informiert, dass die Zeichnung der Kapitalanlage nicht meinem Anlageprofil, aufgrund der von mir angegebenen Kenntnisse und bisherigen Erfahrungen bei Wertpapieren, Vermögensanlagen und sonstigen Kapitalmarktprodukten, entspricht. Ich entscheide mich dennoch für die Zeichnung und den von mir gewählten Betrag.',
    abort: 'Zeichnungsprozess abbrechen',
    next: 'Next',
    back: 'Back',
    modal: {
      title: 'Zeichnungsprozess abbrechen...',
      text: '...und zurück in den Marktplatz?',
      yes: 'Ja, abbrechen',
    },
  },
  costs: {
    title: 'Kosten und Zuwendungen',
    desc: 'Die folgenden Informationen sind gesetzlich vorgeschrieben, um Dir dabei zu helfen, die Kosten dieses Produkts besser einschätzen  und mit anderen Produkten vergleichen zu können.',
    LZ: 'Laufzeit des Finanzinstruments',
    years: 'Years',
    main: {
      KA: 'Ausgabebetrag (inkl. Agio)',
      KE: 'Einmalkosten',
      KL: 'Laufende Kosten p.a.',
      KG: 'Übersicht über Gesamtkosten',
    },
    components: {
      KA: {
        KAA: 'Nominalbetrag',
        KAB: 'Ausgabeaufschlag (Agio für Verwahrung, Versicherung, Instandhaltung, etc.)',
      },
      KE: {
        KEA: 'Kosten der Vermittlung (inkl. Strukturierung des Finanzinstruments) aus Ausgabebetrag',
        KEB: 'Kosten des Finanzinstruments (Transaktions- /Depotkosten)',
      },
      KL: {
        KLA: 'Management Gebühr auf Ausgabebetrag',
        KLB: 'Kosten des Finanzinstruments (Transaktions- /Depotkosten)',
      },
      KG: {
        KGA: 'Kosten reduzieren die Rendite im ersten Jahr um',
        KGB: (ctx) => `Gesamtkosten auf ${ctx.named('years')} Jahre Laufzeit`,
      },
    },
    subComponents: {
      KEA: {
        KEAA: 'Davon Zuwendungen in Bezug auf Ausgabebetrag an Effecta GmbH',
        KEAB: 'Davon an vertraglich gebundenen Vermittler',
      },
      KGB: {
        KGBA: 'Davon Gesamtkosten p.a.',
      },
    },
  },
  signing: {
    title: 'Die unten aufgelisteten Dokumente wirst du auch per Email zugeschickt bekommen',
    desc: 'Gesetzlicher Risikohinweis:<br>Der Erwerb dieses Wertpapiers ist mit erheblichen Risiken verbunden und kann zum vollständigen Verlust des eingesetzten Vermögens führen.',
    footer: {
      title: 'Abschlusshinweis',
      desc: 'Die oben aufgelisteten Dokumente wirst Du nach dem Klick auf "VERBINDLICH ZEICHNEN" im Nachgang per Email zugeschickt bekommen.',
    },
  },
  congratulation: {
    title: 'Your subscription was successful!',
    email: 'We have sent you a confirmation email about your transaction.',
    finish: 'To portfolio',
  },
};
