export default {
  cards: {
    personal: {
      title: 'Konto',
      data: 'Persönliche Daten',
      security: 'Login & Sicherheit',
      KE: 'Kenntnisse & Erfahrungen',
      darkMode: 'Light Mode/ Dark Mode',
    },
    contact: {
      title: 'Mitteilungen',
      notifications: 'Benachrichtigungen',
    },
    documents: {
      title: 'Dokumente',
      billingContracts: 'Abrechnungen & Verträge',
      legal: 'Rechtsinformationen',
    },
    other: {
      title: 'Sonstig',
      signout: 'Abmelden',
      deleteAccount: 'COMETUM-Konto löschen',
    },
    notifications: {
      important: 'Wichtige Benachrichtigungen',
      useful: 'Nützliche Benachrichtigungen',
    },
    comingSoon: 'bald verfügbar',
  },
  views: {
    changePassword: {
      desc: 'Bitte wählen Sie ein sicheres Passwort (mindestens 6 Zeichen und mindestens eine Zahl).',
      form: {
        currentPassword: 'Aktulles Passwort',
        newPassword: 'Neues Passwort',
        submitButton: 'Ändern',
      },
      success: 'Passwort erfolgreich geändert',
    },
    support: {
      email: 'E-Mail',
      phone: 'Phone Number',
      whatsapp: 'WhatsApp',
      telegram: 'Telegram',
    },
    notifications: {
      subscribed: '{type} Benachrichtigungen erfolgreich abonniert',
      unsubscribed: 'Von {type} Benachrichtigungen erfolgreich abgemeldet',
      important: {
        title: 'Wichtige Benachrichtigungen',
        push: {
          title: 'Push Benachrichtigungen',
          subtitle: 'Push-Benachrichtigungen zu Neuemissionen und wichtigen Produktinformationen zulassen',
        },
        email: {
          title: 'Email',
          subtitle: 'E-Mail-Benachrichtigungen zu Neuemissionen und wichtigen Produktinformationen zulassen',
        },
      },
      useful: {
        title: 'Nützliche Benachrichtigungen',
        push: {
          title: 'Push Benachrichtigungen',
          subtitle: 'Push-Benachrichtigungen über interessante Neuigkeiten und neue Assets zulassen',
        },
        email: {
          title: 'E-Mail',
          subtitle: 'E-Mail-Benachrichtigungen über interessante Neuigkeiten und neue Assets zulassen',
        },
        phone: {
          title: 'Telefonanrufe',
          subtitle: 'Telefonanrufe über interessante Neuigkeiten und neue Assets zulassen',
        },
      },
    },
    legal: {
      terms: 'Datenschutzerklärungen',
      privacyPolicy: 'Datenschutzbestimmungen',
      notice: 'Impressum',
    },
    billingContracts: {
      empty: 'Später wirst Du in diesem Bereich deine Abrechnungen und Verträge sehen.',
    },
    deleteAccount: {
      title: 'Sie sind dabei, Ihr Konto endgültig zu löschen',
      subtitle: 'Diese Aktion ist unwiderruflich',
      desc: `
        We are legally obliged to keep some of your data for 5 years.<br/>
        If you would like to know what data we will keep, drop us an email at <a href="mailto:legal@cometum.com" target="_blank">legal@cometum.com</a> and we will export it for you.
`,
      consequences: 'Ich verstehe die Konsequenzen',
      deleteButton: 'Mein Konto löschen',
    },
  },
};
