import deepmerge from 'deepmerge';
import merge from 'lodash.merge';
import {
  updateCurrentInvestor,
  updateInvestmentSurvey as updateUserInvestmentSurvey,
  updateInvestorClassification as updateUserInvestorClassification,
  resetInvestorClassificationToPrivate as resetToPrivate,
  deleteUser,
  getBalance,
  getInvestorPortfolio,
  listEvents as getEvents,
  get as getUserData,
  validateIban as validate,
} from '@/api/users';
import userData from '@/utils/user';

const defaultState = () => {
  const stateObject = {
    data: {
      id: null,
      userRole: null,
      customerId: null,
      email: null,
      mfaStatus: null,
      nextStage: {
        nextMainStage: null,
        nextSubStage: null,
      },
      personalData: {
        firstName: null,
        lastName: null,
        gender: null,
        educationDegree: null,
      },
      generalInformation: {
        customerType: null,
        purposeOfBusiness: null,
        extCustomerId: null,
      },
      identificationData: {
        birthDay: null,
        countryBirth: null,
        countryPrimCitizen: null,
        birthPlace: null,
        isUsCitizen: null,
        isPoliticallyExposed: null,
      },
      contactData: {
        countryResidence: null,
        street: null,
        houseNo: null,
        zip: null,
        city: null,
        phone: null,
        mobile: null,
        mailbox: null,
        email: null,
      },
      financialData: {
        taxNo: null,
        countryTaxation: null,
        refBankIban: null,
        refBankAccOwner: null,
        sourceOfWealth: null,
      },
      videoIdentData: { ...userData.videoIdentData },
      classification: { ...userData.classification },
      investmentSurvey: { ...userData.investmentSurvey },
      settings: {
        notifications: {
          important: {
            push: false,
            email: false,
          },
          useful: {
            push: false,
            email: false,
            phone: false,
          },
        },
      },
      createdAt: null,
      updatedAt: null,
      owner: null,
    },
    onBoardingCompleted: null,
    balance: {
      cashBalance: 0,
      minimumDeposit: 0,
      spendableCashBalance: 0,
      totalSubscriptionAmount: 0,
      paymentId: '',
    },
    portfolio: [],
    events: [],
    bankInfoFromIban: null,
  };
  return stateObject;
};

const state = defaultState();

const getters = {
  user: (state) => state.data,
  id: (state) => state.data.id,
  fullName: (state) => `${state.data.personalData.firstName} ${state.data.personalData.lastName}`,
  investorClassification: (state) => state.data.classification.investorClassification,
  investmentSurvey: (state) => state.data.investmentSurvey,
  videoIdent: (state) => state.data.videoIdentData,
  notificationSettings: (state) => state.data.settings.notifications,
  mainStage: (state) => state.data.nextStage.nextMainStage,
  subStage: (state) => state.data.nextStage.nextSubStage,
  onBoardingCompleted: (state) => state.onBoardingCompleted,
  balance: (state) => state.balance,
  portfolio: (state) => state.portfolio,
  events: (state) => state.events,
  bankInfoFromIban: (state) => state.bankInfoFromIban,
  professionalSecuritiesOptions(state) {
    const options = [];
    Object.values(state.data.investmentSurvey.investmentExperience).forEach((type) => {
      if (type[0].experience === 'GreaterThan5Years') {
        options.push(type[0].type);
      }
      if (type[1].experience === 'GreaterThan5Years') {
        options.push(type[1].type);
      }
    });
    return options;
  },
  showInvestmentClassification(state) {
    let value = false;
    const allSecurities = ['InvestmentFund', 'GovernmentBonds', 'Bond', 'Share', 'CryptoCurrency', 'UnsecuredJuniorDebt'];
    const { investmentExperience } = state.data.investmentSurvey;
    allSecurities.forEach((type) => {
      Object.values(investmentExperience).flat().forEach((security) => {
        if (security.type === type && (security.knowledge && security.experience === 'GreaterThan5Years')) {
          value = true;
        }
      });
    });
    return value;
  },
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  updateUser(state, payload) {
    state.data = merge(state.data, payload);
  },
  setMainStage(state, payload) {
    state.data.nextStage.nextMainStage = payload;
  },
  setSubStage(state, payload) {
    state.data.nextStage.nextSubStage = payload;
  },
  setOnBoardingCompleted(state, payload) {
    state.onBoardingCompleted = payload;
  },
  setBalance(state, payload) {
    state.balance = payload;
  },
  setPortfolio(state, payload) {
    state.portfolio = payload;
  },
  resetUserToPrivate(state) {
    state.data.classification.investorClassification = {
      investorClassification: 'Private',
      isProfessionalForAllSecurities: false,
      professionalSecurities: [],
    };
  },
  setSecurities(state, payload) {
    state.data.classification.investorClassification.professionalSecurities = payload;
  },
  setEvents(state, payload) {
    state.events = payload;
  },
  setBankInfoFromIban(state, payload) {
    state.bankInfoFromIban = payload;
  },
};

const actions = {
  async getUser({ dispatch }) {
    try {
      const user = await getUserData();

      if (!user.classification.investorClassification.investorClassification) {
        user.classification = { ...userData.classification };
      }

      if (!user.classification.conditions?.wealthOver500K) {
        user.classification.conditions = {
          wealthOver500K: false,
          workExperienceFinancialSector: false,
        };
      }

      if (!user.investmentSurvey.serviceExperience) {
        if (user.nextStage.nextSubStage === 'services') {
          user.investmentSurvey.serviceExperience = { ...userData.investmentSurvey.serviceExperience };
        } else {
          user.investmentSurvey = { ...userData.investmentSurvey };
        }
      }

      if (!user.videoIdentData) {
        user.videoIdentData = { ...userData.videoIdentData };
      }

      if (user.identificationData.birthDay && user.identificationData.birthDay.length > 0) {
        const [year, month, day] = user.identificationData.birthDay.split('-');
        user.identificationData.birthDay = `${day}.${month}.${year}`;
      }

      if (user.nextStage.nextMainStage === 'completed' && user.nextStage.nextSubStage === 'completed') {
        dispatch('setOnBoardingCompleted', true);
      } else {
        dispatch('setOnBoardingCompleted', false);
      }

      dispatch('updateUser', user);
      dispatch('setMainStage', user.nextStage.nextMainStage);
      dispatch('setSubStage', user.nextStage.nextSubStage);
    } catch (error) {
      throw new Error(error);
    }
  },
  updateUser({ commit }, payload) {
    commit('updateUser', payload);
  },
  setMainStage({ commit }, payload) {
    const mainStage = Object.keys(this.getters['onboarding/steps'])
      .find((item) => (item.toLowerCase() === payload.toLowerCase()));
    commit('setMainStage', mainStage || 'completed');
  },
  setSubStage({ commit }, payload) {
    const subStage = Object.values(this.getters['onboarding/steps']).flat()
      .find((item) => (item.toLowerCase() === payload.toLowerCase()));
    commit('setSubStage', subStage || 'completed');
  },
  setOnBoardingCompleted({ commit }, payload) {
    commit('setOnBoardingCompleted', payload);
  },
  previousStep({ commit, state }) {
    const index = this.getters['onboarding/steps'][state.data.nextStage.nextMainStage]
      .findIndex((item) => (item === state.data.nextStage.nextSubStage));
    commit('setSubStage', this.getters['onboarding/steps'][state.data.nextStage.nextMainStage][index - 1]);
  },
  async deleteAccount({ commit, dispatch }) {
    try {
      commit('reset');
      // reset the whole state
      await deleteUser();
      await dispatch('auth/signout', null, { root: true });
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateNotificationSettings({ state, commit }, notificationSettings) {
    const input = deepmerge(state.data, notificationSettings);
    try {
      commit('updateUser', input);
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateOnboarding({ commit }, { input, newData }) {
    try {
      const {
        data: {
          updateCurrentInvestor: {
            nextStage,
          },
        },
      } = await updateCurrentInvestor(input);
      if (newData) {
        commit('updateUser', newData);
      }
      return nextStage;
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateInvestmentSurvey({ commit }, { input }) {
    try {
      const {
        data: {
          updateInvestmentSurvey: {
            prokDowngrade,
          },
        },
      } = await updateUserInvestmentSurvey(input);
      if (prokDowngrade) {
        commit('resetUserToPrivate');
      }
      return prokDowngrade;
    } catch (error) {
      throw new Error(error);
    }
  },
  async updateInvestorClassification({ commit }, { input, newData }) {
    try {
      await updateUserInvestorClassification(input);
      commit('updateUser', newData);
      commit('setSecurities', newData.classification.investorClassification.professionalSecurities);
    } catch (error) {
      throw new Error(error);
    }
  },
  async resetInvestorClassificationToPrivate({ commit }) {
    try {
      await resetToPrivate();
      commit('resetUserToPrivate');
    } catch (error) {
      throw new Error(error);
    }
  },
  async getBalance({ commit }) {
    try {
      const balance = await getBalance();
      commit('setBalance', balance);
    } catch (error) {
      throw new Error(error);
    }
  },
  async getPortfolio({ commit }) {
    try {
      const portfolio = await getInvestorPortfolio();
      commit('setPortfolio', portfolio);
    } catch (error) {
      throw new Error(error);
    }
  },
  async listEvents({ commit }) {
    try {
      const events = await getEvents();
      commit('setEvents', events);
    } catch (error) {
      throw new Error(error);
    }
  },
  async validateIban({ commit }, input) {
    try {
      const bankInfoFromIban = await validate(input);
      commit('setBankInfoFromIban', bankInfoFromIban);
    } catch (error) {
      commit('setBankInfoFromIban', null);
      throw new Error(error);
    }
  },
  resetBankInfoFromIban({ commit }) {
    commit('setBankInfoFromIban', null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
