<template>
  <v-app id="app">
    <Loading v-if="loading" />
    <PrivateLayout v-else-if="authenticated && !inProgress" />
    <OnboardingLayout v-else-if="authenticated && inProgress" />
    <PublicLayout v-else />
    <Offline />
    <Notification />
    <!-- <RefreshApp />
    <A2HS /> -->
    <small class="version">{{ version }}</small>
  </v-app>
</template>

<script>
import Amplify from 'aws-amplify';
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export default {
  name: 'App',
  components: {
    // layouts
    PublicLayout: lazyLoad('layouts/Public/Container'),
    PrivateLayout: lazyLoad('layouts/Private/Container'),
    OnboardingLayout: lazyLoad('layouts/Onboarding/Container'),
    // components
    Offline: lazyLoad('views/Offline/Index'),
    Notification: lazyLoad('components/Shared/Notification'),
    // RefreshApp: lazyLoad('components/Shared/RefreshApp'),
    // A2HS: lazyLoad('components/Shared/A2HS'),
    Loading: lazyLoad('components/Shared/Loading'),
  },
  async created() {
    this.$vuetify.theme.dark = false;

    if (this.$store.getters['auth/token']) {
      Amplify.configure({
        API: {
          graphql_headers: async () => ({
            Authorization: this.$store.getters['auth/token'],
            Access: this.$store.getters['auth/access'],
          }),
        },
      });

      await this.$store.dispatch('user/getUser', null, { root: true });
    }
    // document.addEventListener('keydown', (event) => {
    //   // 'ctrl + /' - DEBUGGER
    //   if (event.ctrlKey && event.keyCode === 191) {
    //     this.$refs.debugger.toggle()
    //   }
    // })
    this.$bus.$on('tokenExpired', () => {
      this.$store.dispatch('auth/signout');
      this.$router.push({ name: 'SignIn' });
    });

    await this.$store.dispatch('application/getConfigurations', null, { root: true });

    switch (this.configurations?.stage) {
      case 'dev':
      case 'uat':
      case 'prod':
        datadogLogs.init({
          clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
          site: 'datadoghq.eu',
          service: 'cometum-investor-app',
          env: this.configurations?.stage,
          version: '1.0.0',
          forwardErrorsToLogs: true,
          sampleRate: 100,
        });
        datadogRum.init({
          applicationId: 'c059fd09-2190-439d-92f2-d053a66d4e06',
          clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
          site: 'datadoghq.eu',
          service: 'cometum-investor-app',
          env: this.configurations?.stage,
          // Specify a version number to identify the deployed version of your application in Datadog
          version: '1.0.0',
          sampleRate: 100,
          premiumSampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel: 'mask-user-input',
          allowedTracingOrigins: [
            'https://q4bsxs4aanexpecb5muyei5vpi.appsync-api.eu-central-1.amazonaws.com',
            'https://zf7qhofknjdfjmgahj44cabz2i.appsync-api.eu-central-1.amazonaws.com',
            'https://drygtwmbujhhxkumt4qjrdabry.appsync-api.eu-central-1.amazonaws.com',
          ],
        });
        datadogRum.startSessionReplayRecording();
        break;
      default:
        break;
    }
  },
  computed: {
    ...mapGetters('application', [
      'configurations',
      'loading',
    ]),
    ...mapGetters('auth', [
      'authenticated',
    ]),
    ...mapGetters('onboarding', [
      'inProgress',
    ]),
    version() {
      return process.env.VUE_APP_LATEST;
    },
  },
};
</script>

<style lang="scss">
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none !important;
  width: 0px !important;
  background: transparent !important;
}

body {
  font-family: 'Jost', sans-serif !important;
  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none !important;
  scrollbar-width: none !important; /* Firefox */
}

.v-application {
  font-family: 'Jost', sans-serif !important;
}

#app {
  font-family: 'Jost', sans-serif !important;
  background: var(--v-backgroundDark-base) !important;
}

.centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.icon a {
  text-decoration: none !important;
}

.version {
  display: none;
  text-align: center;
}

.h-full {
  height: 100%;
}

.v-navigation-drawer__border {
  display: none;
}

.ml-minus-1 {
  margin-left: -4px !important;
}

.ml-minus-2 {
  margin-left: -8px !important;
}

.ml-minus-3 {
  margin-left: -12px !important;
}

.ml-minus-4 {
  margin-left: -16px !important;
}

.ml-minus-5 {
  margin-left: -20px !important;
}

.half-opacity {
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer !important;
}

.position-relative {
  position: relative;
}

.v-tooltip__content {
  opacity: 1 !important;
}

@media (max-width: 600px) {
  .v-card {
    padding: 4px !important;
  }
}
</style>
