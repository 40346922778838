import { getConfigurations as get } from '@/api/appConfig';

function defaultState() {
  return {
    pageTitle: '',
    loading: false,
    enums: {
      Gender: [],
      AssetType: [],
      Status: [],
    },
    notification: {
      current: undefined,
      colors: {
        error: 'orange',
        success: 'green',
        info: 'blue',
      },
      types: [
        'error',
        'success',
        'info',
      ],
    },
    support: {
      email: 'support@cometum.com',
      phone: '+49 1511 802 65 40',
      whatsapp: '+49 1511 802 65 40',
      telegram: 'https://t.me/cometum',
    },
    configurations: null,
    timeout: null,
  };
}

const state = defaultState();

const getters = {
  notification: (state) => state.notification.current,
  colors: (state) => state.notification.colors,
  pageTitle: (state) => state.pageTitle,
  support: (state) => state.support,
  loading: (state) => state.loading,
  configurations: (state) => state.configurations,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  notify(state, payload) {
    clearTimeout(state.timeout);
    state.notification.current = payload;
    const { timeout } = payload;
    if (timeout !== -1) {
      state.timeout = setTimeout(() => { this.commit('application/resetNotification'); }, timeout);
    }
  },
  resetNotification(state) {
    state.notification.current = undefined;
  },
  setPageTitle(state, pageTitle) {
    state.pageTitle = pageTitle;
  },
  loading(state, loading) {
    state.loading = loading;
  },
  enums(state, enums) {
    state.enums = enums;
  },
  setConfigurations(state, payload) {
    state.configurations = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  notificationReset({ commit }) {
    commit('resetNotification');
  },
  notify({ commit }, payload) {
    commit('notify', payload);
  },
  setPageTitle({ commit }, pageTitle) {
    commit('setPageTitle', pageTitle);
  },
  globalStateReset() {
    this.reset();
  },
  loading({ commit }, loading) {
    commit('loading', loading);
  },
  async getConfigurations({ commit }) {
    try {
      const {
        data: {
          getConfigurations: configurations,
        },
      } = await get();
      commit('setConfigurations', configurations);
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
