export default {
  onboarding: {
    genders: {
      male: 'männlich',
      female: 'weiblich',
    },
    educationDegrees: {
      university: 'Hochschulabschluss',
      secondarySchool: 'Abitur (allgemeine oder fachgebundene Hochschulreife)',
      technicalCollege: 'Fachhochschulreife (allgemeine oder fachgebundene Fachhochschulreife)',
      middleCollege: 'Mittlerer Schulabschluss (Realschulabschluss und vergleichbare Schulabschlüsse)',
      basicCollege: 'Hauptschulabschluss und qualifizierender Hauptschulabschluss',
      other: 'Sonstige',
      none: 'Keine',
    },
    sourcesOfWealth: {
      Salary: 'Gehalt',
      Pension: 'Pension',
      CapitalIncome: 'Kapitaleinkünfte',
      RentalIncome: 'Mieteinnahmen',
      Loans: 'Darlehen',
      InsuranceClaims: 'Versicherungsansprüche',
      Divorce: 'Scheidung',
      Lottery: 'Lotterie',
      Savings: 'Ersparnis',
      Heritage: 'Erbe',
      Gift: 'Geschenk',
      Investments: 'Investitionen',
      LifeInsurance: 'Lebensversicherung',
      Companies: 'Unternehmen',
      Judgements: 'Gerichtsurteile',
      Crypto: 'Krypto',
    },
    investmentSurvey: {
      products: {
        InvestmentFund: 'Investmentfonds',
        GovernmentBonds: 'Staatsanleihen',
        MoneyMarketFunds: 'Geldmarktfonds',
        Bond: 'Anleihen',
        Share: 'Aktien',
        Certificate: 'Zertifikate',
        ReverseConvertibleBond: 'Aktienanleihen',
        AlternativeInvestmentFund: 'Alternative Investmentfonds',
        Warrants: 'Optionsscheine',
        EmergingMarketBonds: 'Anleihen aus Schwellenländern',
        HedgeFund: 'Hedgefonds',
        FuturesTrading: 'Termingeschäfte / Derivate',
        ParticipationRight: 'Genussrechte',
        ParticipationPaper: 'Genussscheine',
        OperativeParticipationInALimitedPartnership: 'Operative KG-Beteiligung',
        ParticipationInALimitedPartnership: 'KG-Beteiligung',
        ContractForDifference: 'CFDs (Contract-For-Difference)',
        CryptoCurrency: 'Virtuelle Währungen',
        UnsecuredJuniorDebt: 'Nachrangdarlehen',
        PrivateEquity: 'Private Equity',
        FixedDeposit: 'Festgeld',
      },
      knowledge: {
        yes: 'Ja',
        no: 'Nein',
      },
      // Translation of DB enums
      RangeOfExperience: {
        LowerThan3Years: 'Bis 3 Jahre',
        Bet3To5Years: '3 bis 5 Jahre',
        GreaterThan5Years: 'Über 5 Jahre',
      },
      AvgYearlyTransactionsType: {
        NoTrans: 'Keine',
        Lower5Trans: 'Bis zu 5',
        Bet5To10Trans: '5-10',
        MoreThan10Trans: 'Mehr als 10',
      },
      AvgTransactionValueType: {
        Lower3000: '< 3.000€',
        Bet3000To5000: '3.001€ - 5.000€',
        Bet5000To10000: '5.001€ - 10.000€',
        Over10000: '> 10.000€',
      },
    },
  },
};
