import { getField, updateField } from 'vuex-map-fields';
import genders from '@/mappings/onboarding/genders';
import educationDegrees from '@/mappings/onboarding/educationDegrees';
import sourcesOfWealth from '@/mappings/onboarding/sourcesOfWealth';
import {
  knowledge, experiences, transactionNumber, transactionVolume,
} from '@/mappings/onboarding/investmentSurvey';
import {
  germany, allCountries, ewrCountries,
} from '@/mappings/onboarding/countries';

function defaultState() {
  return {
    section: 'personal',
    navigation: {
      steps: {
        personal: [
          'PersonalData',
          'GeneralInformation',
          'ContactData',
          'IdentificationData',
          'TaxInformation',
          'BankAccountInformation',
          'SourceOfWealthInformation',
          'Overview',
        ],
        investmentSurvey: [
          'Intro',
          'Products',
          'Services',
          'Overview',
        ],
        classification: [
          'Acknowledgement',
          'Conditions',
          'InvestorClassification',
          'Overview',
        ],
        videoIdent: [
          'Information',
          'Selection',
        ],
        contractClosing: [
          'Overview',
        ],
        investorApproval: [
          'VideoIdent',
          'MinimumDeposit',
        ],
      },
      index: {
        personal: 0,
        classification: 0,
        investmentSurvey: 0,
        videoIdent: 0,
      },
    },
    icons: {
      personal: 'mdi-account-outline',
      classification: 'mdi-file-document-outline',
      investmentSurvey: 'mdi-lightbulb-outline',
      videoIdent: 'mdi-lock-outline',
      contractClosing: 'mdi-file-edit-outline',
      investorApproval: 'mdi-file-check-outline',
    },
    investmentSurvey: {
      investmentExperience: {
        basic: [
          'InvestmentFund',
          'GovernmentBonds',
        ],
        expanded: [
          'Bond',
          'Share',
        ],
        extensiveOrSpecial: [
          'CryptoCurrency',
          'UnsecuredJuniorDebt',
        ],
      },
    },
    inProgress: false,
    isFormValid: true,
    isLoading: false,
    editMode: null,
    euCitizen: false,
    agreeResponsibility: false,
    agreeProcessing: false,
    genders,
    educationDegrees,
    sourcesOfWealth,
    knowledge,
    experiences,
    transactionNumber,
    transactionVolume,
    serviceExperienceDone: false,
    productExperienceDone: false,
    onboardingModal: false,
  };
}
const state = defaultState();

const getters = {
  getField,
  section: (state) => state.section,
  editMode: (state) => state.editMode,
  steps: (state) => state.navigation.steps,
  index: (state) => state.navigation.index,
  icons: (state) => state.icons,
  isFormValid: (state) => state.isFormValid,
  isLoading: (state) => state.isLoading,
  inProgress: (state) => state.inProgress,
  genders: (state) => state.genders,
  educationDegrees: (state) => state.educationDegrees,
  sourcesOfWealth: (state) => state.sourcesOfWealth,
  products: (state) => Object.values(state.investmentSurvey.investmentExperience).flat(1),
  knowledge: (state) => state.knowledge,
  experiences: (state) => state.experiences,
  transactionNumber: (state) => state.transactionNumber,
  transactionVolume: (state) => state.transactionVolume,
  serviceExperienceDone: (state) => state.serviceExperienceDone,
  productExperienceDone: (state) => state.productExperienceDone,
  allCountries: () => allCountries(),
  ewrCountries: () => ewrCountries(),
  defaultCountry: () => germany,
  onboardingModal: (state) => state.onboardingModal,
};

const mutations = {
  updateField,
  reset(state) {
    Object.assign(state, defaultState());
  },
  editMode(state, payload) {
    state.editMode = payload;
  },
  inProgress(state, payload) {
    state.inProgress = payload;
  },
  setCompleted(state, payload) {
    state.completed = payload;
  },
  setFormValidity(state, payload) {
    state.isFormValid = payload;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setServiceExperienceDone(state, payload) {
    state.serviceExperienceDone = payload;
  },
  setProductExperienceDone(state, payload) {
    state.productExperienceDone = payload;
  },
  setOnboardingModal(state, payload) {
    state.onboardingModal = payload;
  },
};

const actions = {
  inProgress({ commit }, payload) {
    commit('inProgress', payload);
  },
  editMode({ commit }, payload) {
    commit('editMode', payload);
  },
  setCompleted({ commit }, payload) {
    commit('setCompleted', payload);
  },
  setFormValidity({ commit }, payload) {
    commit('setFormValidity', payload);
  },
  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  },
  setServiceExperienceDone({ commit }, payload) {
    commit('setServiceExperienceDone', payload);
  },
  setProductExperienceDone({ commit }, payload) {
    commit('setProductExperienceDone', payload);
  },
  setOnboardingModal({ commit }, payload) {
    commit('setOnboardingModal', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
