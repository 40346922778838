import apiMakeRequest from '@/plugins/apiRequest/index';
import {
  getInvestor,
  getInvestorBalance,
  getPortfolio,
  triggerPayout as payout,
  listEvents as getEvents,
  validateIban as validate,
} from '@/graphqlts/queries';
import {
  login as loginInvestor,
  refreshSession as refresh,
  registerInvestor,
  confirmRegistration as confirmRegistrationInvestor,
  resendCode as resendCodeInvestor,
  forgotPasswordInit as forgotPasswordInitInvestor,
  forgotPasswordComplete as forgotPasswordCompleteInvestor,
  updateCurrentInvestor as updateCurrentInvestorOnboarding,
  updateInvestmentSurvey as updateUserInvestmentSurvey,
  updateInvestorClassification as updateUserInvestorClassification,
  resetInvestorClassificationToPrivate as resetToPrivate,
  deleteUser as deleteCurrentUser,
  changePassword as changeUserPassword,
} from '@/graphqlts/mutations';

const get = async () => {
  try {
    const {
      data: {
        getInvestor: investor,
      },
    } = await apiMakeRequest(getInvestor);
    return investor;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const getInvestorPortfolio = async () => {
  try {
    const {
      data: {
        getPortfolio: portfolio,
      },
    } = await apiMakeRequest(getPortfolio);
    return portfolio;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const deleteUser = async () => {
  try {
    await apiMakeRequest(deleteCurrentUser);
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const login = async (input) => {
  try {
    return await apiMakeRequest(loginInvestor, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const refreshSession = async (input) => {
  try {
    return await apiMakeRequest(refresh, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const register = async (input) => {
  try {
    return await apiMakeRequest(registerInvestor, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const confirm = async (input) => {
  try {
    return await apiMakeRequest(confirmRegistrationInvestor, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const resendCode = async (email) => {
  try {
    return await apiMakeRequest(resendCodeInvestor, { email });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const forgotPasswordInit = async (email) => {
  try {
    return await apiMakeRequest(forgotPasswordInitInvestor, { email });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const forgotPasswordComplete = async (input) => {
  try {
    return await apiMakeRequest(forgotPasswordCompleteInvestor, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const updateCurrentInvestor = async (input) => {
  try {
    return await apiMakeRequest(updateCurrentInvestorOnboarding, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const updateInvestmentSurvey = async (input) => {
  try {
    return await apiMakeRequest(updateUserInvestmentSurvey, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const updateInvestorClassification = async (input) => {
  try {
    return await apiMakeRequest(updateUserInvestorClassification, { input });
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const resetInvestorClassificationToPrivate = async () => {
  try {
    return await apiMakeRequest(resetToPrivate);
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const getBalance = async () => {
  try {
    const {
      data: {
        getBalance: balance,
      },
    } = await apiMakeRequest(getInvestorBalance);
    return balance;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const triggerPayout = async (input) => {
  try {
    const {
      data: {
        triggerPayout: response,
      },
    } = await apiMakeRequest(payout, { input });
    return response;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const listEvents = async () => {
  try {
    const {
      data: {
        listEvents: response,
      },
    } = await apiMakeRequest(getEvents);
    return response;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const changePassword = async (input) => {
  try {
    const {
      data: {
        changePassword: response,
      },
    } = await apiMakeRequest(changeUserPassword, { input });
    return response;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

const validateIban = async (input) => {
  try {
    const {
      data: {
        validateIban: response,
      },
    } = await apiMakeRequest(validate, input);
    return response;
  } catch (error) {
    throw error.errors[0].errorInfo;
  }
};

export {
  get,
  getInvestorPortfolio,
  deleteUser,
  login,
  refreshSession,
  register,
  confirm,
  resendCode,
  forgotPasswordInit,
  forgotPasswordComplete,
  updateCurrentInvestor,
  updateInvestmentSurvey,
  updateInvestorClassification,
  resetInvestorClassificationToPrivate,
  getBalance,
  triggerPayout,
  listEvents,
  changePassword,
  validateIban,
};
