export default {
  light: {
    primary: '#B39771',
    primaryDark: '#B39771',
    secondary: '#1F3828',
    error: '#C4252B',
    errorBackround: '#2C1C04',
    info: '#2672D3',
    success: '#41B01E',
    warning: '#E39828',
    danger: '#fad7d8',
    background: '#F8F8F8',
    backgroundLight: '#EEEEEE',
    backgroundDark: '#FCFCFC',
    backgroundDarkest: '#ECE5DB',
    element: '#F8F8F8',
    euro: '#EACF75',
    funds: '#827AEF',
    securitizedtoken: '#D66B7A',
    stablecoin: '#90D96C',
    cryptoAsset: '#6CC7D9',
    artworks: '#D36BD6',
    vehicles: '#D66B7A',
    defaultText: '#000000',
    secondaryText: '#9A9A9A',
    reverseText: '#FFFFFF',
  },
  dark: {
    primary: '#E6C8A0',
    primaryDark: '#B39771',
    secondary: '#1F3828',
    error: '#E39828',
    errorBackround: '#2C1C04',
    info: '#2672D3',
    success: '#41B01E',
    warning: '#E39828',
    danger: '#2C2734',
    background: '#1F2A38',
    backgroundLight: '#273A53',
    backgroundDark: '#192128',
    backgroundDarkest: '#0E1A25',
    element: '#1F2A38',
    euro: '#EACF75',
    funds: '#827AEF',
    securitizedtoken: '#D66B7A',
    stablecoin: '#90D96C',
    cryptoAsset: '#6CC7D9',
    artworks: '#D36BD6',
    vehicles: '#D66B7A',
    defaultText: '#FFFFFF',
    secondaryText: '#A5AAAF',
    reverseText: '#000000',
  },
};
