import eventBus from '@/plugins/eventBus';

const checkTokenValidity = async (error: any) => {
  if (error.errors[0].message.includes('Invalid token supplied')) {
    eventBus.$emit('tokenExpired');
  }
};

export {
  checkTokenValidity,
};
