export default {
  required: 'Field is required',
  generic: {
    required: 'Field is required',
    alphanum: 'Field must be alphanumeric',
    numbers: 'Only numbers are allowed',
  },
  email: {
    required: 'E-mail is required',
    valid: 'E-mail must be valid',
  },
  password: {
    required: 'Password is required',
    length: 'Password must have at least 6 characters',
    lowercase: 'Must have lowercase character',
    uppercase: 'Must have uppercase character',
    number: 'Must have number',
    symbol: 'Must have symbol',
  },
  tos: {
    required: 'You must agree to continue!',
  },
  names: {
    required: 'Field is required',
    alpha: 'Name must be alphabetic letters',
  },
  street: {
    required: 'Field is required',
    alpha: 'Street must be alphabetic',
  },
  city: {
    required: 'Field is required',
    alpha: 'City must be alphabetic',
  },
  zip: {
    required: 'Field is required',
    length: 'German postal code can only have 5 digits',
    numeric: 'German Postal code must only contain numbers',
  },
  birthDay: {
    required: 'Field is required',
    format: 'Please enter the date in the format DD.MM.YYYY',
    minAge: 'You need to be at least 18 years old!',
  },
  isUsCitizen: {
    required: 'You may not be US-Citizen to become a customer of COMETUM!',
  },
  isPoliticallyExposed: {
    required: 'You may not be a PEP to become a customer of COMETUM!',
  },
  alphanumField: {
    required: 'Field is required',
    alphanum: 'Field must be alphanumeric',
  },
  mobile: {
    required: 'Field is required',
    invalid: 'Invalid phone number',
  },
  iban: {
    required: 'Field is required',
    alphanum: 'IBAN must contain uppercase letters and numbers',
    minlength: 'IBAN must have at least 16 characters',
    maxlength: 'IBAN must have at most 34 characters',
    invalid: 'Invalid IBAN',
  },
  bic: {
    invalid: 'Invalid BIC',
  },
  taxNumber: {
    required: 'Field is required',
  },
  url: {
    required: 'Field is required',
    valid: 'Invalid URL',
  },
  investAmount: {
    min: (ctx) => `Die Mindestzeichnungssumme für das Produkt beträgt  ${ctx.named('minAmount')} €.`,
    max: {
      LimitBalance: (ctx) => `There are fees on top of the subscription amount. You can only invest up to €${ctx.named('maxAmount')}.`,
      LimitTokens: (ctx) => `There are only ${ctx.named('maxAmount')} token available for this product.`,
      LimitRegulation: 'Due to regulatory constraints, the maximum subscription amount for this product is €25.000.',
    },
  },
  withdrawAmount: 'The amount exceeds your account balance.',
};
