import { API, graphqlOperation } from 'aws-amplify';
import { checkTokenValidity } from '@/plugins/apiRequest/interceptors';

const apiMakeRequest = async (operation, params = {}) => {
  try {
    return await API.graphql(graphqlOperation(operation, params));
  } catch (error) {
    checkTokenValidity(error);
    throw error;
  }
};

export default apiMakeRequest;
