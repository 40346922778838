export default {
  sections: {
    personal: {
      title: 'Persönliche Informationen',
      steps: {
        RegistrationProcessOverview: 'Übersicht',
        PersonalData: 'Persönliche Daten',
        GeneralInformation: 'Allgemeine Informationen',
        ContactData: 'Anschrift',
        IdentificationData: 'Identifikationsdaten',
        TaxInformation: 'Steuerinformationen',
        BankAccountInformation: 'Bankkonto',
        SourceOfWealthInformation: 'Vermögensherkunft',
        Overview: 'Zusammenfassung',
      },
    },
    classification: {
      title: 'Investor Klassifikation',
      steps: {
        RegistrationProcessOverview: 'Übersicht',
        Acknowledgement: 'Einstufungsantrag',
        Conditions: 'Einstufungskriterien',
        InvestorClassification: 'Einstufung',
        Overview: 'Einverständniserklärung',
      },
    },
    investmentSurvey: {
      title: 'Kenntnisse & Erfahrungen',
      steps: {
        RegistrationProcessOverview: 'Übersicht',
        Intro: 'Einführung',
        Products: 'Produkte',
        Services: 'Dienstleistungen',
        Overview: 'Zusammenfassung',
      },
    },
    videoIdent: {
      title: 'Videoidentifizierung',
      steps: {
        RegistrationProcessOverview: 'Übersicht',
        Information: 'Kontosicherheit',
        Selection: 'Auswahl',
      },
    },
    contractClosing: {
      title: 'Vertragsabschluss',
      steps: {
        Overview: 'Zusammenfassung',
      },
    },
    investorApproval: {
      title: 'Freigabe',
      steps: {
        VideoIdent: 'Identifizierung',
        MinimumDeposit: 'Mindesteinzahlung',
      },
    },
  },
  modal: {
    desc: 'Schließe jetzt Deine Registrierung ab, um sofort alle Funktionen von COMETUM nutzen zu können.',
    next: 'Registrierung abschließen',
    cancel: 'Abbrechen',
  },
  navButtons: {
    back: 'Zurück',
    next: 'Weiter',
    nextLater: 'Weiter und später durchführen',
    becomePro: 'Jetzt professioneller kunde werden',
    finish: 'Leg los!',
  },
  generalInformation: {
    desc: 'Wir weisen darauf hin, dass COMETUM momentan nur Privatkunden und keine juristischen Personen zulässt.',
    customerType: 'Hiermit bestätige ich, dass ich COMETUM ausschließlich für private Anlagezwecke nutze.',
  },
  personalData: {
    desc: 'Wir benötigen nur wenige Informationen, um Dein Konto zu erstellen.',
    fields: {
      gender: 'Geschlecht',
      firstName: 'Vorname',
      lastName: 'Nachname',
      educationDegree: 'Höchster Bildungsabschluss',
    },
    error: 'Aus technischen Gründen ist eine Abfrage derzeit nicht möglich. Unser Team ist informiert und kümmert sich bereits um eine schnelle Lösung. Bitte versuche es später erneut.',
  },
  contactData: {
    desc: 'Bitte teile uns Informationen zu Deinem offiziellen Wohnsitz mit.',
    fields: {
      countryResidence: 'Land',
      street: 'Straße',
      houseNo: 'Hausnummer',
      zip: 'Postleitzahl',
      city: 'Stadt',
      mobile: 'Mobilnummer',
      mailbox: 'Mailbox',
      phone: 'Telefonnummer',
      email: 'Email',
    },
  },
  identificationData: {
    desc: 'Bitte gib Dein Geburtsdatum und Deinen Geburtsort an.',
    fields: {
      birthDay: 'Geburtstag',
      birthPlace: 'Geburtsort',
      countryBirth: 'Geburtsland',
      isUsCitizen: 'Ich bin kein US-Staatsbürger und nicht in USA oder Kanada steuerpflichtig.',
      isPoliticallyExposed: 'Ich bin keine politisch exponierte Person (PEP).',
      countryPrimCitizen: 'Land der Staatsangehörigkeit',
    },
    tooltipMessage: 'Eine politisch exponierte Person (PEP) ist ein/e Politiker/in oder eine Person in deren unmittelbarem Umfeld. Nach den Vorschriften zur Bekämpfung von Geldwäsche oder Terrorismusfinanzierung dürfen wir aktuell solche Personen nicht als Kunde aufnehmen. Du kannst die Anmeldung jederzeit durch einen Klick auf den Schließen-Button abbrechen.',
  },
  taxInformation: {
    descOne: 'Wir benötigen folgende Steuerdaten von Dir.',
    descTwo: 'Wenn Du in mehr als einem Land steuerflichtig bist, beginne mit deinem Hauptwohnsitz.',
    addBtn: 'Ich bin in einem weiteren Land steuerpflichtig',
    fields: {
      countryTaxation: 'Steuerpflichtig in',
      taxNo: 'Steueridentifikationsnummer',
      errorMsg: 'Die angegebene Steueridentifikationsnummer scheint nicht korrekt zu sein.',
    },
  },
  bankAccountInformation: {
    title: 'Bitte bestimme Dein Referenzbankkonto.',
    desc: 'Dieses Konto wird für alle Deine Ein- und Auszahlungen auf der COMETUM-Plattform genutzt.',
    fields: {
      refBankAccOwner: 'Bankkontoinhaber',
      refBankIban: 'IBAN',
      refBankName: 'Referenzbank',
      refBankBic: 'BIC',
    },
  },
  sourceOfWealthInformation: {
    desc: 'Bitte gib an, woher Dein Vermögen stammt.',
    fields: {
      sourceOfWealth: 'Herkunft des Vermögens',
    },
  },
  dataOverview: {
    desc: 'Bitte verifiziere, ob Deine Angaben korrekt sind.',
    subTitles: {
      personalData: 'Persönliche Informationen',
      contactData: 'Anschrift',
      identificationData: 'Geburtsdaten',
      financialData: 'Finanzielle Informationen',
    },
  },
  registrationProcessOverview: {
    title: 'Unser Registrierungsprozess',
    steps: {
      account: 'Anmeldung',
      personal: 'Angabe persönlicher Daten',
      classification: 'Investor Klassifikation',
      investmentSurvey: 'Kenntnisse & Erfahrungen',
      videoIdent: 'Videoidentifizierung',
      contractClosing: 'Vertragsabschluss',
      investorApproval: 'Freigabe',
    },
    personal: {
      title: 'Persönliche Informationen',
      desc: 'Wir freuen uns, dass Du Dich für digitales Private Banking von COMETUM entschieden hast. Um Deine Registrierung abzuschließen, benötigen wir noch einige persönliche Informationen von Dir. Dies sollte nicht länger als 10 Minuten dauern. Im Anschluss musst Du Dich noch mit dem Verfahren der Videoidentifizierung identifizieren. Hierzu sind wir aufgrund des Geldwäschegesetz (GWG) verpflichtet.</br></br>Dein COMETUM Team',
    },
    classification: {
      title: 'Investor Klassifikation',
      desc: 'In den nächsten Schritten benötigen wir weitere Informationen, um Dein Anlegerprofil zu erstellen.<br/><br/>Wir können anhand Deiner Antworten feststellen, ob Du Privatkunde bist oder Dich als Professioneller Kunde nach Anhang II der Richtlinie 2014/65/EU (MiFID II) qualifizierst. Im Anschluss kannst Du Dich entscheiden, ob wir unsere Services diesbezüglich für Dich anpassen sollen.',
    },
    investmentSurvey: {
      title: 'Kenntnisse & Erfahrungen',
      desc: 'Die folgenden Fragen helfen uns dabei, Deine Kenntnisse & Erfahrungen in Bezug auf Finanzanlageprodukte besser einschätzen zu können und ein Anlegerprofil für Dich zu erstellen.',
    },
    videoIdent: {
      title: 'Videoidentifizierung',
      desc: 'Du hast es fast geschafft. Abschließend müssen Deine Angaben zu Deiner Person durch eine Videoidentifizierung verifiziert werden. Dafür wirst Du an unseren Partner IDnow weitergeleitet. Bitte folge hierzu den Anweisungen.',
    },
    contractClosing: {
      title: 'Vertragsabschluss',
      desc: 'Um als Investor auf der Plattform investieren zu können, bestätige bitte die folgenden Verträge.',
    },
    investorApproval: {
      title: 'Freigabe',
      desc: 'Sobald die Videoidentifizierung mit unserem Partner IDnow abgeschlossen ist und die Mindesteinlage eingezahlt wurde, kannst Du mit dem Investieren beginnen.',
    },
    completed: {
      title: 'Registrierung erfolgreich abgeschlossen',
      desc: 'Du hast es geschafft!<br/>Bei Fragen steht Dir das COMETUM Team jederzeit zur Verfügung.',
    },
  },
  classification: {
    acknowledgement: {
      question: 'Wenn Du gewisse Bedingungen erfüllst, hast Du die Möglichkeit, Dich als Professioneller Kunde einstufen zu lassen.<br/><br/>Hier kannst Du im Detail sehen, was der Unterschied zwischen einem Privatkunden und einem Professionellen Kunden ist und was dies für Dein Schutzniveau und Deine Anlagefreiheit bedeutet.',
      answer: {
        yes: 'Ich möchte mich als Professioneller Kunde einstufen lassen.',
        no: 'Ich genieße lieber das umfangreiche Schutzniveau, welches die gesetzliche Regelung für Privatkunden vorsieht und verzichte auf eventuelle Vorteile.',
      },
    },
    conditions: {
      intro: 'Gemäß den Vorschriften des Wertpapierhandelsgesetzes (WpHG) sind Wertpapierinstitute (WPI) verpflichtet, Deine Eignung als Professioneller Kunde zu überprüfen. Hierzu bitten wir Dich zunächst um Beantwortung der nachfolgenden Fragen.',
      conditions: {
        wealthOver500K: {
          question: 'Wie hoch schätzt Du Dein liquides Vermögen (Barvermögen und Finanzinstrumente)?',
          answer: {
            yes: '> 500.000 €',
            no: '< 500.000 €',
          },
        },
        workExperienceFinancialSector: {
          question: 'Hast Du für mindestens ein Jahr einen Beruf im Finanzsektor ausgeübt, welcher Kenntnisse über die geplanten Geschäfte und Dienstleistungen voraussetzt?',
          answer: {
            yes: 'Ja',
            no: 'Nein',
          },
        },
        profession: {
          title: 'Bitte spezifiziere Deine berufliche Position (z.B. Börsenmakler, Bankkaufmann oder Vermögensverwalter).',
          placeholder: 'Beruf',
        },
      },
      modalDescription: {
        pro: 'Aufgrund Deiner Angaben besteht für Dich die Möglichkeit, Dich als Professioneller Kunde einstufen zu lassen.',
        private: 'Gemäß den Vorschriften des Wertpapierhandelsgesetzes (WpHG) erfüllst Du leider noch nicht alle Kriterien. Natürlich kann sich das in der Zukunft ändern. Wir fragen Dich in Zukunft regelmäßig wieder danach. Bis dahin genießt Du das Schutzniveau eines Privatanlegers.',
      },
    },
    investorClassification: {
      intro: 'Hinsichtlich welcher Geschäftsart/en wünschst Du eine Einstuftung als Professioneller Kunde?',
      radio: {
        all: 'Für alle Geschäftsarten auf COMETUM.',
        choose: 'Nur für Geschäftsarten, für die ich auf Basis meiner Angaben qualifiziert bin.',
      },
      modal: {
        text: 'Wähle die Option “Für alle Geschäftsarten auf COMETUM”, um eine Einstufung als Professioneller Kunde für alle Assets und Geschäftsarten vorzunehmen, in welche Du auf COMETUM zum jetzigen Zeitpunkt investieren kannst.',
        button: 'Okay',
      },
      notify: 'Du wurdest erfolgreich als Professioneller Kunde eingestuft.',
      skip: {
        title: 'Abbrechen und weiter als Privatkunde',
        modal: {
          question: 'Bist Du sicher, dass Du die Einstufung als Professioneller Kunde abbrechen willst und als Privatkunde fortfahren möchtest?',
          answer: {
            yes: 'Ja',
            no: 'Nein',
          },
        },
      },
    },
    documents: {
      title: 'Möchtest Du künftig als Professioneller Kunde eingestuft werden?',
      description: 'Für diese Geschäftsart/en beantragst Du eine Einstufung als Professioneller Kunde:',
      intro: 'Als Professioneller Kunde hast Du neben uneingeschränkten Anlagemöglichkeiten ein limitiertes Schutzniveau.',
      introDocuments: 'Weitere Informationen findest Du in den folgenden Dokumenten.',
      footer: 'Mit dem Klick auf den Button "JETZT PROFESSIONELLER KUNDE WERDEN" wird Dein Antrag auf Einstufung als Professioneller Kunde verbindlich.',
    },
  },
  investmentSurvey: {
    views: {
      intro: {
        intro: 'Um Deine Kenntnisse & Erfahrungen in Bezug auf Investitionen besser einschätzen zu können, sind wir gemäß Wertpapierhandelsgesetz (WpHG) verpflichtet, Deine Kenntnisse und Erfahrungen für die folgenden Finanzanlageprodukte abzufragen.',
        outro: 'Mit Hilfe Deiner Antworten können wir bewerten, ob die von uns angebotenen Finanzanlageprodukte zu Deinem Anlegerprofil passen.',
      },
      products: {
        title: 'Anlageprodukte',
        knowledgeQuestion: (product) => `Hast Du bereits Kenntnisse in Bezug auf ${product.named('product')}?`,
        experienceQuestion: (product) => `Wie würdest Du Deine Erfahrungen mit ${product.named('product')} einschätzen?`,
      },
      services: {
        title: 'Wertpapierdienstleistungen',
        experienceQuestion: 'Wie würdest Du Deine Erfahrungen mit Wertpapierdienstleistungen einschätzen?',
      },
      overview: {
        title: 'Deine Kenntnisse und Erfahrungen auf einen Blick',
        intro: 'Hier findest Du Deine bisher gemachten Angaben. Du kannst jetzt und auch später Änderungen vornehmen.',
        status: 'Kenntnisse',
        edit: 'Änderung',
      },
    },
    components: {
      skip: {
        trigger: 'Eignungstest überspringen',
        title: 'Enthaltung der Angabe von Kentnisse und Erfahrungen',
        confirmation: 'Hiermit bestätige ich dass ich den Eignungstest überspringen will und dass ich keine Angaben zu meinen Kenntnissen und Erfahrungen machen möchte',
        cancel: 'Abbrechen',
        next: 'Weiter',
      },
    },
    products: {
      InvestmentFund: 'Investment Fonds',
      GovernmentBonds: 'Staatsanleihen',
      Bond: 'Anleihen',
      Share: 'Aktien',
      CryptoCurrency: 'Kryptowährungen / Security Token',
      UnsecuredJuniorDebt: 'Nachrangdarlehen / Partizipationsdarlehen',
    },
    services: {
      independent: 'Eigenständig / Beratungsfrei',
      investmentConsultancy: 'Mit Anlageberatung',
      investmentManagement: 'Mit Vermögensverwaltung',
      avgYearlyTransactions: 'Wie viele Transaktionen tätigst Du durchschnittlich pro Jahr?',
      avgTransactionValue: 'Wie hoch ist Dein durchschnittliches Investitionsvolumen pro Transaktion?',
    },
  },
  videoIdentInformation: {
    desc: 'Bitte lies folgende Informationen und schließe die Videoidentifizierung mit unserem Partner IDnow ab.',
    accountSecurity: {
      title: 'Kontosicherheit',
      desc: 'Der Schutz Deines Vermögens ist uns wichtig. Daher legen wir von Anfang an hohen Wert auf den Schutz Deiner persönlichen Daten und Deiner Privatsphäre. Zur Einhaltung geldwäscherechtlicher Anforderungen sind wir verpflichtet eine Indentitätsüberprüfung zu Deiner Person durchzuführen. Hierzu werden personenbezogene Daten von Dir im Sinne des § 6 Abs. 1 Satz 1a Datenschutzgrundverordnung DSGVO an unseren Partner IDnow übermittelt und verarbeitet.',
    },
    biometricData: {
      title: 'Information zu biometrischen Daten',
      desc: 'Für die Videoidentifizierung verarbeiten wir biometrische Daten. Diese werden nach der Verifizierung sofort gelöscht.',
    },
    checkbox: {
      responsibility: 'Hiermit bestätige ich, dass ich von keiner dritten Person kontaktiert oder angewiesen wurde, diese Verifizierung durchzuführen, und dass ich mich aus eigenem Antrieb bei COMETUM anmelde, um digitale Vermögenswerte zu kaufen und zu verkaufen.',
      processing: 'Ich bin damit einverstanden, dass meine biometrischen Daten als personenbezogene Daten, im Sinne des § 6 Abs. 1 Satz 1a Datenschutzgrundverordnung DSGVO, zum Zwecke der Identifikation mittels Videoidentifizierung von COMETUM im Rahmen der Auftragsverarbeitung an die Bankhaus von der Heydt GmbH & Co. KG und die IDnow GmbH weitergegeben und verarbeitet werden. Ich habe zur Kenntnis genommen, dass die Einwilligung nicht widerrufen werden kann.',
    },
  },
  videoIdent: {
    title: 'Schließe die Videoidentifizierung mit unseren Partnern IDnow und Bankhaus von der Heydt ab.',
    desc: 'Bitte wähle eine der folgenden Optionen.',
    browser: {
      title: 'Browser',
      desc: 'Starte die Videoidentifizierung über Deinen Browser.',
      button: 'Videoidentifizierung starten',
    },
    mobileApp: {
      title: 'App',
      desc1: 'Nutze die IDnow Ident Mobile App für die Videoidentifizierung.',
      desc2: 'Starte die App und gib folgende Ident-ID für die Videoidentifizierung an:',
      ccNotification: 'Erfolgreich Deinen Ident-ID zum Clipboard kopiert',
      subtitle: 'Checklist für die Videoidentifizierung per App',
      list: {
        1: 'Halte Deinen <span class="defaultText--text">Personalausweis</span> oder Deinen Reisepass bereit',
        2: 'Halte ein aktuelles <span class="defaultText--text">Dokument, mit dem Deine Adresse verifiziert werden kann</span> (z.B. Stromrechnung, Internetrechnung, Meldebescheinigung oder Kontoauszug), bereit',
        3: 'Starte die <span class="defaultText--text">IDnow Ident App</span> auf Deinem Smartphone',
        4: 'Gib die <span class="defaultText--text">Ident-ID</span> für die Videoidentifizierung in der App an',
        5: 'Bestätige bei IDnow, dass Du Dich für das <span class="defaultText--text">Bankhaus von der Heydt</span> verifizierst',
        6: 'Nach erfolgreicher Verifizierung leiten wir Dich von hier <span class="defaultText--text">automatisch zum nächsten Schritt</span>',
      },
    },
    triggerKycStatusChange: {
      info: 'Du befindest dich auf unserem Test System! Wenn Du den VideoIdent abgeschlossen hast, klicke auf diesen Button!',
      button: 'Trigger Kyc Status Change',
    },
    error: 'Leider gibt es Probleme bei unserem Partner IDnow. Wir arbeiten derzeit an einer Lösung. Bitte versuche es später noch einmal.',
  },
  investorApproval: {
    title: 'Willkommen bei COMETUM!',
    desc: (info) => `Damit Du Dein erstes Investment tätigen kannst, zahle auf Deinen COMETUM-Account eine Mindesteinlage von ${info.named('minimumDeposit')} ein.<br>Auf Deinem COMETUM-Account befinden sich aktuell ${info.named('cashBalance')}.`,
    subtitle: 'Geld einzahlen',
    subDescription: 'Die folgenden Schritte erklären, wie Du auf Deinen COMETUM-Account Geld einzahlen kannst.',
    listItemOne: 'Unten in der Karte findest Du eine IBAN und eine Payment-ID.',
    listItemTwo: 'Um Geld einzuzahlen, überweise einen Betrag an dieses Konto und gib in der Überweisung die Payment-ID als Verwendungszweck an.',
    cardSubtitle: 'COMETUM-ID 92749821',
    cardItemOne: 'IBAN',
    cardItemTwo: 'Payment-ID',
    copy: 'Copied to clipboard',
    triggerMinimumDeposit: {
      info: 'Du befindest dich auf unserem Test System! Klicke den unteren Button um Dir ECHTES Testgeld i.H.v. 150.000,00 EUR zu überweisen!',
      button: 'Trigger Deposit',
    },
    error: 'wir haben Probleme deinen Kontostand zu ermitteln. Wir arbeiten bereits daran das Problem zu lösen, bitte versuche es später erneut.',
  },
  popup: {
    title: 'Hinweis',
    subtitle: 'Registrieung abschließen',
    desc: 'Schließe jetzt Deine Registrierung ab, um auf COMETUM investieren zu können.',
    close: 'Abbrechen',
    next: 'Registrierung abschließen',
  },
  complete: {
    key: 'Dokumentenvorlage mit Schlüssel',
    missing: 'is missing!',
  },
  contractClosing: {
    title: 'Überprüfe Deinen Auftrag',
    subtitle: 'Im Rahmen der Nutzung der COMETUM Plattform, schließt Du verschiedene Verträge mit unseren Partnern, dem Bankhaus von der Heydt GmbH & Co. KG und der Effecta GmbH.',
    description: 'Wir haben Deine Daten übernommen und die Vertragsunterlagen nachfolgend für Dich aufgelistet. Erst mit dem abschließenden Klick “Jetzt COMETUM Konto eröffnen” werden die Vertragsunterlagen an uns übermittelt.',
    subDescription: 'Bitte lies Dir alle Vertragsunterlagen sorgfältig durch und nimm diese zur Kenntnis.',
    footer: 'Im Anschluss erhältst Du von uns eine Bestätigungs-Email, mit der Möglichkeit sämtliche Vertragsdokumente einzusehen und herunterzuladen.',
  },
  cometum: {
    title: 'COMETUM: Kundenvereinbarung ',
    description: 'Im Folgenden findest Du die COMETUM-Kundenvereinbarung zur Nutzung unserer Plattform.',
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich handle auf eigene Rechnung und im eigenen Namen.</li><li>Ich habe die COMETUM Kundenvereinbarung (samt Widerrufsbelehrung) und das Dokument “Risiken der Kapitalanlage, Preis- und Leistungsverzeichnis” erhalten.</li><li>Ich beantrage den Abschluss des Kundenvertrags mit der COMETUM GmbH zu den Vertragsbedingungen.</li><li>Ich bin damit einverstanden, dass die COMETUM GmbH unverzüglich nach Beendigung des Nutzungsvertrages alle meine Daten innerhalb des COMETUM IT-Systems an den Emittenten des Investment-Tokens weitergeben wird. Die Erteilung dieser Einwilligung ist freiwillig. Ich kann sie jederzeit mit Wirkung für die Zukunft widerrufen. Allerdings ist mir bewusst, dass in diesem Fall keine Weitergabe meiner Daten an den Emittenten erfolgen wird. Weitere Einzelheiten finden sich in der Datenschutzerklärung von COMETUM.</li></ul>',
    customer_agreement: {
      pdf: {
        title: 'COMETUM AGB',
      },
    },
    risks: {
      pdf: {
        title: 'Risiken der Kapitalanlage',
      },
    },
  },
  effecta: {
    title: 'Effecta: Kundeninformationen',
    description: 'COMETUM wird als vertraglich gebundener Vermittler bei der Vermittlung von Finanzinstrumenten ausschließlich im Namen, auf Rechnung und unter Haftung des Wertpapierinstituts Effecta GmbH tätig. Deswegen solltest Du vor Abschluss Deiner Investition die Kundeninformationen der Effecta GmbH zur Kenntnis nehmen.',
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich habe die “Effecta Kundeninformationen” erhalten und von ihrem Inhalt Kenntnis genommen.</li><li>Ich stimme der Verarbeitung und Übertragung meiner Daten, im Zuge des Vertragsschlusses, an das Haftungsdach der COMETUM GmbH, dem unabhängigen Finanzdienstleistungsinstitut Effecta GmbH Florstadt, zu.</li></ul>',
    customer_information: {
      pdf: {
        title: 'Effecta - Kundeninformationen',
      },
    },
  },
  bvdh: {
    title: 'Bankhaus von der Heydt: Vertragsdokumente',
    description: 'Um bei COMETUM investieren zu können, ist es notwendig ein Konto mit Krypto-Wallet bei unserem Partner zu eröffnen, dem Bankhaus von der Heydt.',
    checkboxText: 'Ich gebe folgende Erklärungen ab:<br><br><ul><li>Ich handele im eigenen Namen und auf eigene Rechnung.</li><li>Ich bin mit dem Vertragsgegenstand einverstanden.</li><li>Ich stimme den Vertragsbedingungen für ein Krypto-Wallet mit Verrechnungskonto zu.</li><li>Ich habe folgende Dokumente erhalten: Bank AGB, Preis- und Leistungsverzeichnis ("PLV"), Verwahrbedingungen und Verbraucherinformationen inkl. Widerrufsrecht.</li><li>Ich möchte ein Konto/Wallet bei dem Bankhaus von der Heydt eröffnen.</li></ul>',
    bank_terms_and_conditions: {
      pdf: {
        title: 'Bankhaus von der Heydt - Bank AGB',
      },
    },
    custody: {
      pdf: {
        title: 'Bankhaus von der Heydt - Verwahrbedingungen',
      },
    },
    customer_information: {
      pdf: {
        title: 'Bankhaus von der Heydt - Verbraucherinformationen inkl. Widerrufsbelehrung',
      },
    },
    price_sheet: {
      pdf: {
        title: 'Bankhaus von der Heydt - Preis- Leistungsverzeichnis',
      },
    },
  },
  general: {
    title: 'Ihre Bestätigung ist erforderlich',
    description: '',
    checkboxText: 'Hiermit willige ich ein:<br><br><ol><li>Meine Angaben sind vollständig und korrekt.</li><li>Mit der Aufzeichnung von Telefongesprächen und elektronischer Kommunikation bin ich einverstanden.</li><li>Ich werde nachträglich eintretende Änderungen, der in diesem Formular gemachten Angaben, unverzüglich der COMETUM GmbH mitteilen und durch entsprechende Dokumente (Kopie des Ausweises etc.) nachweisen.</li></ol>',
    customer_information: {
      pdf: {
        title: 'Effecta - Kundeninformationen',
      },
    },
  },
  warning: 'Jetzt Registrierung abschließen, um investieren zu können',
};
