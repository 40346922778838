import { Amplify } from 'aws-amplify';
import {
  login as loginUser,
  refreshSession as refreshUser,
} from '@/api/users';
import i18n from '@/plugins/i18n';
import '@/utils/array';
import { datadogRum } from '@datadog/browser-rum';

function setAmplifyAuthHeaders(headers) {
  Amplify.configure({
    API: {
      graphql_headers: async () => (headers),
    },
  });
}

function addAmplifyAuthHeader(token, access) {
  setAmplifyAuthHeaders({
    Authorization: token,
    Access: access,
  });
}

function removeAmplifyAuthHeader() {
  setAmplifyAuthHeaders({});
}

function defaultState() {
  return {
    authenticated: false,
    token: null,
    refresh: null,
    access: null,
  };
}

const state = defaultState();

const getters = {
  authenticated: (state) => state.authenticated,
  token: (state) => state.token,
  access: (state) => state.access,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  setAuth(state, payload) {
    state.authenticated = payload.isAuthenticated;
    state.token = payload.token;
    state.refresh = payload.refresh;
    state.access = payload.access;
  },
  updateUser(state, payload) {
    state.user = { ...state.user, ...payload };
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  async signin({ commit, dispatch, rootState }, auth) {
    dispatch('application/globalStateReset', null, { root: true });
    try {
      const {
        data: {
          login: {
            access,
            token,
            refresh,
          },
        },
      } = await loginUser(auth);

      addAmplifyAuthHeader(token, access);
      await dispatch('application/getConfigurations', null, { root: true });
      await dispatch('user/getUser', null, { root: true });
      commit('setAuth', {
        isAuthenticated: true, token, refresh, access,
      });
      datadogRum.setUser({
        id: rootState.user.data.id,
        name: `${rootState.user.data.personalData.firstName} ${rootState.user.data.personalData.lastName}`,
        email: rootState.user.data.email,
      });
    } catch (error) {
      switch (error.code) {
        case 'UserNotFoundException':
        case 'NotAuthorizedException':
          throw new Error(i18n.t(`auth.cognitoMessages.${error.code}`));
        default:
          throw new Error(i18n.t(`auth.cognitoMessages.GenericException`));
      }
    }
  },
  async refreshSession({ commit, dispatch, state }, email) {
    try {
      const {
        data: {
          refreshSession: {
            access,
            token,
            refresh,
          },
        },
      } = await refreshUser({
        email,
        refreshToken: state.refresh,
      });

      addAmplifyAuthHeader(token, access);
      await dispatch('application/getConfigurations', null, { root: true });
      await dispatch('user/getUser', null, { root: true });
      commit('setAuth', {
        isAuthenticated: true, token, refresh, access,
      });
    } catch (error) {
      switch (error.code) {
        case 'UserNotFoundException':
        case 'NotAuthorizedException':
          throw new Error(i18n.t(`auth.cognitoMessages.${error.code}`));
        default:
          throw new Error(i18n.t(`auth.cognitoMessages.GenericException`));
      }
    }
  },
  async signout({ commit }) {
    try {
      removeAmplifyAuthHeader();
      datadogRum.removeUser();
      commit('setAuth', { isAuthenticated: false, token: null });
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
