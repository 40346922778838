function defaultState() {
  return {
    navigation: {
      steps: [
        'Amount',
        'Confirm',
        'Finish',
      ],
      index: 0,
    },
    amount: 0,
  };
}

const state = defaultState();

const getters = {
  step: (state) => state.navigation.steps[state.navigation.index],
  amount: (state) => state.amount,
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  nextStep(state) {
    if (state.navigation.index !== state.navigation.steps.length) {
      state.navigation.index += 1;
    }
  },
  previousStep(state) {
    if (state.navigation.index > 0) state.navigation.index -= 1;
  },
  setAmount(state, payload) {
    state.amount = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  nextStep({ commit }) {
    commit('nextStep');
  },
  previousStep({ commit }) {
    commit('previousStep');
  },
  setAmount({ commit }, payload) {
    commit('setAmount', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
