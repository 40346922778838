import apiMakeRequest from '@/plugins/apiRequest/index';
import {
  getInvestorSubscriptionInformation as getInformation,
  checkAdequacy as check,
  getSubscriptionFees as getFees,
  getSubscriptionDocuments as getDocuments,
} from '@/graphqlts/queries';
import {
  initSubscriptionOrder as init,
  confirmInvestmentSurvey as confirmSurvey,
  requestSubscriptionTokenAmount as request,
  confirmInadequacy as confirm,
  createSubscriptionOrder as create,
} from '@/graphqlts/mutations';

const get = async (assetId) => {
  try {
    const {
      data: {
        getInvestorSubscriptionInformation: information,
      },
    } = await apiMakeRequest(getInformation, { assetId });
    return information;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const checkAdequacy = async (orderId) => {
  try {
    const {
      data: {
        checkAdequacy: adequacy,
      },
    } = await apiMakeRequest(check, { orderId });
    return adequacy;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const getSubscriptionFees = async (orderId) => {
  try {
    const {
      data: {
        getSubscriptionFees: response,
      },
    } = await apiMakeRequest(getFees, { orderId });
    return response;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const getSubscriptionDocuments = async (orderId) => {
  try {
    const {
      data: {
        getSubscriptionDocuments: response,
      },
    } = await apiMakeRequest(getDocuments, { orderId });
    return response;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const initSubscriptionOrder = async (assetId) => {
  try {
    const {
      data: {
        initSubscriptionOrder: {
          orderId: response,
        },
      },
    } = await apiMakeRequest(init, { assetId });
    return response;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const confirmInvestmentSurvey = async (orderId) => {
  try {
    await apiMakeRequest(confirmSurvey, { orderId });
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const requestSubscriptionTokenAmount = async (input) => {
  try {
    await apiMakeRequest(request, { input });
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const confirmInadequacy = async (orderId) => {
  try {
    await apiMakeRequest(confirm, { orderId });
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

const createSubscriptionOrder = async (orderId) => {
  try {
    const {
      data: {
        createSubscriptionOrder: response,
      },
    } = await apiMakeRequest(create, { orderId });
    return response;
  } catch (error) {
    throw new Error('Error getting user record:', error);
  }
};

export {
  get,
  checkAdequacy,
  getSubscriptionFees,
  getSubscriptionDocuments,
  initSubscriptionOrder,
  confirmInvestmentSurvey,
  requestSubscriptionTokenAmount,
  confirmInadequacy,
  createSubscriptionOrder,
};
