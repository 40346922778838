import store from '@/store';
import lazyLoad from '@/utils/lazyLoad';

const onboardingCheck = (from, next) => {
  if (!store.getters['user/onBoardingCompleted']) {
    store.dispatch('onboarding/setOnboardingModal', true);
    next({ name: from.name });
  }
  next();
};

export default [
  {
    path: '/settings',
    name: 'Settings.Index',
    component: lazyLoad('views/Settings/Index'),
    beforeEnter: (_, from, next) => {
      onboardingCheck(from, next);
    },
  },
  {
    path: '/settings/knowledge-experience',
    name: 'Settings.KnowledgeExperience',
    component: lazyLoad('views/Settings/KnowledgeExperience'),
    beforeEnter: (_, from, next) => {
      onboardingCheck(from, next);
    },
  },
  {
    path: '/settings/security',
    name: 'Settings.Security',
    component: lazyLoad('views/Settings/Security'),
  },
  {
    path: '/settings/security/password',
    name: 'Settings.Password',
    component: lazyLoad('views/Settings/Password'),
  },
  {
    path: '/settings/investor-classification',
    name: 'Settings.InvestorClassification',
    component: lazyLoad('views/Settings/InvestorClassification'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/professionalSecuritiesOptions'].length < 1) {
        next({ name: 'Settings.KnowledgeExperience' });
      }
      next();
    },
  },
  {
    path: '/settings/notifications',
    name: 'Settings.Notifications',
    component: lazyLoad('views/Settings/Notifications'),
    beforeEnter: (_, from, next) => {
      onboardingCheck(from, next);
    },
  },
  {
    path: '/settings/profile',
    name: 'Settings.Profile',
    component: lazyLoad('views/Settings/Profile'),
    beforeEnter: (_, from, next) => {
      onboardingCheck(from, next);
    },
  },
  {
    path: '/settings/delete-test-account',
    name: 'Settings.DeleteTestAccount',
    component: lazyLoad('views/Settings/DeleteTestAccount'),
  },
];
