export default {
  onboarding: {
    genders: {
      male: 'male',
      female: 'female',
    },
    educationDegrees: {
      university: 'University Degree',
      secondarySchool: 'Secondary School Diploma',
      technicalCollege: 'Technical College Degree',
      middleCollege: 'Middle College Degree',
      basicCollege: 'Basic College Degree',
      other: 'Other',
      none: 'None',
    },
    sourcesOfWealth: {
      Salary: 'Salary',
      Pension: 'Pension',
      CapitalIncome: 'Capital Income',
      RentalIncome: 'Rental Income',
      Loans: 'Loans',
      InsuranceClaims: 'Insurance Claims',
      Divorce: 'Divorce',
      Lottery: 'Lottery',
      Savings: 'Savings',
      Heritage: 'Heritage',
      Gift: 'Gift',
      Investments: 'Investments',
      LifeInsurance: 'Life Insurance',
      Companies: 'Companies',
      Judgements: 'Judgements',
      Crypto: 'Crypto',
    },
    investmentSurvey: {
      products: {
        InvestmentFund: 'InvestmentFund',
        GovernmentBonds: 'GovernmentBonds',
        MoneyMarketFunds: 'MoneyMarketFunds',
        Bond: 'Bond',
        Share: 'Share',
        Certificate: 'Certificate',
        ReverseConvertibleBond: 'ReverseConvertibleBond',
        AlternativeInvestmentFund: 'AlternativeInvestmentFund',
        Warrants: 'Warrants',
        EmergingMarketBonds: 'EmergingMarketBonds',
        HedgeFund: 'HedgeFund',
        FuturesTrading: 'FuturesTrading',
        ParticipationRight: 'ParticipationRight',
        ParticipationPaper: 'ParticipationPaper',
        OperativeParticipationInALimitedPartnership: 'OperativeParticipationInALimitedPartnership',
        ParticipationInALimitedPartnership: 'ParticipationInALimitedPartnership',
        ContractForDifference: 'ContractForDifference',
        CryptoCurrency: 'CryptoCurrency',
        UnsecuredJuniorDebt: 'UnsecuredJuniorDebt',
        PrivateEquity: 'PrivateEquity',
        FixedDeposit: 'FixedDeposit',
      },
      knowledge: {
        yes: 'Yes',
        no: 'No',
      },
      // Translation of DB enums
      RangeOfExperience: {
        LowerThan3Years: 'Less than 3 years',
        Bet3To5Years: '3 to 5 years',
        GreaterThan5Years: 'More than 5 years',
      },
      AvgYearlyTransactionsType: {
        NoTrans: 'None',
        Lower5Trans: 'Less than 5',
        Bet5To10Trans: '5-10',
        MoreThan10Trans: 'More than 10',
      },
      AvgTransactionValueType: {
        Lower3000: '< 3.000€',
        Bet3000To5000: '3.001€ - 5.000€',
        Bet5000To10000: '5.001€ - 10.000€',
        Over10000: '> 10.000€',
      },
    },
  },
};
