export default {
  required: 'Eingabe erforderlich',
  generic: {
    required: 'Eingabe erforderlich',
    alphanum: 'Eingabe muss alphanumerisch sein',
    numbers: 'Eingabe muss numerisch sein',
  },
  email: {
    required: 'Eingabe erforderlich',
    valid: 'E-mail ist ungültig',
  },
  password: {
    required: 'Eingabe erforderlich',
    length: 'Passwort muss mindestens 6 Zeichen enthalten',
    lowercase: 'Passwort muss einen Kleinbuchstaben enthalten',
    uppercase: 'Passwort muss einen Großbuchstaben enthalten',
    number: 'Passwort muss eine Zahl enthalten',
    symbol: 'Passwort muss ein Sonderzeichen enthalten',
  },
  tos: {
    required: 'Zustimmung erforderlich!',
  },
  names: {
    required: 'Eingabe erforderlich',
    alpha: 'Name darf nur alphabetische Zeichen enthalten',
  },
  street: {
    required: 'Eingabe erforderlich',
    alpha: 'Straßenname muss alphabetisch sein',
  },
  city: {
    required: 'Eingabe erforderlich',
    alpha: 'Stadtname muss alphabetisch sein',
  },
  zip: {
    required: 'Eingabe erforderlich',
    length: 'Deutsche Postleitzahl darf nur 5 Stellen haben',
    numeric: 'Deutsche Postleitzahl darf nur Zahlen enthalten',
  },
  birthDay: {
    required: 'Eingabe erforderlich',
    format: 'Bitte gib das Datum im Format DD.MM.YYYY ein',
    minAge: 'Mindestalter von 18 Jahren erforderlich!',
  },
  isUsCitizen: {
    required: 'Leider dürfen wir aktuell noch keine US-Staatsbürger als Kunden aufnehmen.',
  },
  isPoliticallyExposed: {
    required: 'Wir dürfen keine politisch exponierten Personen als Kunden aufnehmen.',
  },
  alphanumField: {
    required: 'Eingabe erforderlich',
    alphanum: 'Eingabe muss alphanumerisch sein',
  },
  mobile: {
    required: 'Eingabe erforderlich',
    invalid: 'Ungültige Mobilnummer',
  },
  iban: {
    required: 'Eingabe erforderlich',
    alphanum: 'IBAN darf nur Großbuchstaben und Zahlen enthalten',
    minlength: 'IBAN muss mindestens 16 Zeichen enthalten',
    maxlength: 'IBAN darf maximal 34 Zeichen enthalten',
    invalid: 'Ungültige IBAN',
  },
  bic: {
    invalid: 'Ungültige BIC',
  },
  taxNumber: {
    required: 'Eingabe erforderlich',
  },
  url: {
    required: 'Eingabe erforderlich',
    valid: 'Ungültige URL',
  },
  investAmount: {
    min: (ctx) => `Die Mindestzeichnungssumme für das Produkt beträgt  ${ctx.named('minAmount')} €.`,
    max: {
      LimitBalance: (ctx) => `Zur Zeichnungssumme fallen für das Produkt Gebühren an. Maximal kannst Du noch ${ctx.named('maxAmount')} € investieren.`,
      LimitTokens: (ctx) => `Für dieses Produkt sind nur noch ${ctx.named('maxAmount')} Token verfügbar.`,
      LimitRegulation: 'Aufgrund aufsichtsrechtlicher Vorgaben beträgt der maximale Anlagebetrag pro Person bei diesem Kapitalmarktprodukt 25.000 Euro.',
    },
  },
  withdrawAmount: 'Der Betrag übersteigt Deinen Kontostand.',
};
