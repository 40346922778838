import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import publicRoutes from '@/router/public';
import privateRoutes from '@/router/private';

Vue.use(VueRouter);

const routes = publicRoutes.concat(privateRoutes);

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/portfolio',
      meta: {
        public: false,
      },
    },
  ].concat(routes),
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  store.dispatch('application/setPageTitle', to.name);
  const authenticated = store.getters['auth/authenticated'];
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut);

  if (!isPublic && !authenticated) {
    return next({
      name: 'SignIn',
      query: { redirect: to.fullPath },
    });
  }

  if (authenticated && onlyLoggedOut) {
    return next('/');
  }

  if (from.name) {
    if (from.name.includes('Onboarding')) {
      store.dispatch('onboarding/inProgress', false);
    }
  }

  if (to.name.includes('Onboarding')) {
    store.dispatch('onboarding/inProgress', true);
  } else {
    store.dispatch('onboarding/inProgress', false);
  }

  return next();
});

export default router;
