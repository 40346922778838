import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/locales/en/messages';
import de from '@/locales/de/messages';
import enDateTime from '@/locales/en/dateTime';
import deDateTime from '@/locales/de/dateTime';

Vue.use(VueI18n);

const messages = {
  'en-US': en,
  'de-DE': de,
};

const dateTimeFormats = {
  'en-US': enDateTime,
  'de-DE': deDateTime,
};

const numberFormats = {
  'en-US': {
    integer: {
      style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0,
    },
    float: {
      style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
    integerCurrency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', maximumFractionDigits: 0, minimumFractionDigits: 0,
    },
    floatCurrency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
    percent: {
      style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
  },
  'de-DE': {
    integer: {
      style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0,
    },
    float: {
      style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
    integerCurrency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', maximumFractionDigits: 0, minimumFractionDigits: 0,
    },
    floatCurrency: {
      style: 'currency', currency: 'EUR', currencyDisplay: 'symbol', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
    percent: {
      style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2,
    },
  },
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: process.env.NODE_ENV === 'development'
    ? navigator.languages[0]
    : 'de-DE',
  messages,
  dateTimeFormats,
  numberFormats,
});

export default i18n;
