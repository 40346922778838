import lazyLoad from '@/utils/lazyLoad';
import store from '@/store';

export default [
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: lazyLoad('layouts/Onboarding/Container'),
  },
  {
    path: '/onboarding/overview',
    name: 'Onboarding.Overview',
    component: lazyLoad('views/Onboarding/RegistrationProcessOverview'),
  },
  {
    path: '/onboarding/personal/:step',
    name: 'Onboarding.Personal',
    component: lazyLoad('views/Onboarding/Personal/Index'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/mainStage'] !== 'personal' || store.getters['user/subStage'] !== to.params.step) {
        next({ name: 'Onboarding.Overview' });
      }
      next();
    },
  },
  {
    path: '/onboarding/investmentSurvey/:step',
    name: 'Onboarding.InvestmentSurvey',
    component: lazyLoad('views/Onboarding/InvestmentSurvey/Index'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/mainStage'] !== 'investmentSurvey' || store.getters['user/subStage'] !== to.params.step) {
        next({ name: 'Onboarding.Overview' });
      }
      next();
    },
  },
  {
    path: '/onboarding/classification/:step',
    name: 'Onboarding.Classification',
    component: lazyLoad('views/Onboarding/Classification/Index'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/mainStage'] !== 'classification' || store.getters['user/subStage'] !== to.params.step) {
        next({ name: 'Onboarding.Overview' });
      }
      next();
    },
  },
  {
    path: '/onboarding/videoIdent/:step',
    name: 'Onboarding.VideoIdent',
    component: lazyLoad('views/Onboarding/VideoIdent/Index'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/mainStage'] !== 'videoIdent' || store.getters['user/subStage'] !== to.params.step || store.getters['user/onBoardingCompleted']) {
        next({ name: 'Onboarding.Overview' });
      }
      next();
    },
  },
  {
    path: '/onboarding/contractClosing/:step',
    name: 'Onboarding.ContractClosing',
    component: lazyLoad('views/Onboarding/ContractClosing/Index'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/mainStage'] !== 'contractClosing' || store.getters['user/subStage'] !== to.params.step || store.getters['user/onBoardingCompleted']) {
        next({ name: 'Onboarding.Overview' });
      }
      next();
    },
  },
  {
    path: '/onboarding/investorApproval/:step',
    name: 'Onboarding.InvestorApproval',
    component: lazyLoad('views/Onboarding/InvestorApproval/Index'),
    beforeEnter: (to, from, next) => {
      if (store.getters['user/mainStage'] !== 'investorApproval' || store.getters['user/subStage'] !== to.params.step || store.getters['user/onBoardingCompleted']) {
        next({ name: 'Onboarding.Overview' });
      }
      next();
    },
  },
];
