import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persistedstate';
import { createStore } from 'vuex-extensions';

import application from '@/store/modules/application';
import onboarding from '@/store/modules/onboarding';
import assets from '@/store/modules/assets';
import documents from '@/store/modules/documents';
import auth from '@/store/modules/auth';
import registration from '@/store/modules/registration';
import settings from '@/store/modules/settings';
import forgotPassword from '@/store/modules/forgotPassword';
import subscription from '@/store/modules/subscription';
import user from '@/store/modules/user';
import withdraw from '@/store/modules/withdraw';

Vue.use(Vuex);

const persistSettings = {
  key: 'cometum',
  storage: window.sessionStorage,
};

const vuexLocalStorage = new (VuexPersist as any)(persistSettings);

const strict = process.env.NODE_ENV !== 'production';

export default createStore(Vuex.Store, {
  modules: {
    application,
    auth,
    forgotPassword,
    onboarding,
    assets,
    documents,
    registration,
    settings,
    subscription,
    user,
    withdraw,
  },
  strict,
  plugins: [vuexLocalStorage],
});
