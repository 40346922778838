export default {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  monthYear: {
    year: 'numeric',
    month: 'short',
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'long',
  },
  digit: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  document: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
};
