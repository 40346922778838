/* eslint-disable no-shadow */
import {
  get as getInvestorInformation,
  checkAdequacy as check,
  getSubscriptionFees as getFees,
  getSubscriptionDocuments as getDocuments,
  initSubscriptionOrder as init,
  confirmInvestmentSurvey as confirmSurvey,
  requestSubscriptionTokenAmount as request,
  confirmInadequacy as confirm,
  createSubscriptionOrder as createOrder,
} from '../../api/subscription';

function defaultState() {
  return {
    navigation: {
      steps: [
        'Experiences',
        'Amount',
        'AdequacyCheck',
        'Costs',
        'Signing',
        'Congratulation',
      ],
      index: 0,
    },
    investorSubscriptionInformation: null,
    passedAdequacyCheck: false,
    amount: null,
    subscriptionFees: null,
    subscriptionOrder: null,
    orderId: null,
    assetId: null,
    documents: null,
  };
}
const state = defaultState();

const getters = {
  steps: (state) => state.navigation.steps,
  index: (state) => state.navigation.index,
  step: (state) => state.navigation.steps[state.navigation.index],
  investorSubscriptionInformation: (state) => state.investorSubscriptionInformation,
  passedAdequacyCheck: (state) => state.passedAdequacyCheck,
  amount: (state) => state.amount,
  subscriptionFees: (state) => state.subscriptionFees,
  subscriptionOrder: (state) => state.subscriptionOrder,
  subscriptionDocuments: (state) => state.subscriptionDocuments,
  orderId: (state) => state.orderId,
  assetId: (state) => state.assetId,
  lastStep: (state) => state.navigation.index === state.navigation.steps.length - 1,
  subscriptionAmount(state) {
    return state.investorSubscriptionInformation.tokenPrice
    * state.amount;
  },
  debitAmount(state) {
    return state.investorSubscriptionInformation.debitAmountFactor
    * state.investorSubscriptionInformation.tokenPrice
    * state.amount;
  },
};

const mutations = {
  reset(state) {
    Object.assign(state, defaultState());
  },
  nextStep(state, payload) {
    if (state.navigation.index !== state.navigation.steps.length) {
      state.navigation.index += payload;
    }
  },
  previousStep(state, payload) {
    if (state.navigation.index > 0) state.navigation.index -= payload;
  },
  setInvestorSubscriptionInformation(state, payload) {
    state.investorSubscriptionInformation = payload;
  },
  setPassedAdequacyCheck(state, payload) {
    state.passedAdequacyCheck = payload;
  },
  setAmount(state, payload) {
    state.amount = payload;
  },
  setSubscriptionFees(state, payload) {
    state.subscriptionFees = payload;
  },
  setSubscriptionOrder(state, payload) {
    state.subscriptionOrder = payload;
  },
  setSubscriptionDocuments(state, payload) {
    state.subscriptionDocuments = payload;
  },
  setOrderId(state, payload) {
    state.orderId = payload;
  },
  setAssetId(state, payload) {
    state.assetId = payload;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  nextStep({ commit }, step) {
    commit('nextStep', step);
  },
  previousStep({ commit }, step) {
    commit('previousStep', step);
  },
  setAmount({ commit }, amount) {
    commit('setAmount', amount);
  },
  async getInvestorSubscriptionInformation({ commit }, assetId) {
    try {
      const investorSubscriptionInformation = await getInvestorInformation(assetId);
      commit('setInvestorSubscriptionInformation', investorSubscriptionInformation);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async checkAdequacy({ commit, state }) {
    try {
      const adequacy = await check(state.orderId);
      commit('setPassedAdequacyCheck', adequacy);
      return adequacy;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async getSubscriptionFees({ commit, state }) {
    try {
      const subscriptionFees = await getFees(state.orderId);
      commit('setSubscriptionFees', subscriptionFees);
      return subscriptionFees;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async getSubscriptionDocuments({ commit, state }) {
    try {
      const subscriptionDocuments = await getDocuments(state.orderId);
      commit('setSubscriptionDocuments', subscriptionDocuments);
      return subscriptionDocuments;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async initSubscriptionOrder({ commit }, assetId) {
    try {
      const orderId = await init(assetId);
      commit('setOrderId', orderId);
      commit('setAssetId', assetId);
      return orderId;
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async confirmInvestmentSurvey({ state }) {
    try {
      await confirmSurvey(state.orderId);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async requestSubscriptionTokenAmount(_, data) {
    try {
      await request(data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async confirmInadequacy({ state }) {
    try {
      await confirm(state.orderId);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  async createSubscriptionOrder({ commit, state }) {
    try {
      const subscription = await createOrder(state.orderId);
      commit('setSubscriptionOrder', subscription);
      return subscription;
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
