import {
  getAsset, listInvestorAssets, listAssetImage, listDocumentTemplate,
} from '@/graphqlts/queries';
import apiMakeRequest from '@/plugins/apiRequest/index';

const get = async (id) => {
  try {
    const {
      data: {
        getAsset: asset,
      },
    } = await apiMakeRequest(getAsset, { id });
    return asset;
  } catch (error) {
    throw new Error(`Couldn't get asset details: ${error}`);
  }
};

const all = async () => {
  try {
    const {
      data: {
        listInvestorAssets: assets,
      },
    } = await apiMakeRequest(listInvestorAssets);
    return assets;
  } catch (error) {
    throw new Error(`Couldn't fetch assets: ${error.errors[0].message}`);
  }
};

const getImages = async (input) => {
  try {
    const {
      data: {
        listAssetImage: {
          assetImages: images,
        },
      },
    } = await apiMakeRequest(listAssetImage, { input });
    return images;
  } catch (error) {
    throw new Error(`Couldn't fetch assets: ${error.errors[0].message}`);
  }
};

const getDocuments = async (input) => {
  try {
    const {
      data: {
        listDocumentTemplate: {
          documentTemplates: documents,
        },
      },
    } = await apiMakeRequest(listDocumentTemplate, { input });
    return documents;
  } catch (error) {
    throw new Error(`Couldn't fetch assets: ${error.errors[0].message}`);
  }
};

export {
  get,
  all,
  getImages,
  getDocuments,
};
