/* eslint-disable no-shadow */
import {
  get as getDocument,
} from '@/api/documents';

function defaultState() {
  return {
    documents: null,
  };
}

const state = defaultState();

const getters = {
  get: (state) => state.documentTemplate,
};

const mutations = {
  setDocumentTemplate(state, payload) {
    state.documentTemplate = payload;
  },
};

const actions = {
  async getDocumentTemplate({ commit }, input) {
    try {
      const documentTemplate = await getDocument(input);
      commit('setDocumentTemplate', documentTemplate);
      return documentTemplate;
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
