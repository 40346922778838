import i18n from '@/plugins/i18n';

export default [
  {
    text: i18n.t('mappings.onboarding.educationDegrees.university'),
    value: 'Hochschulabschluss',
  },
  {
    text: i18n.t('mappings.onboarding.educationDegrees.secondarySchool'),
    value: 'Abitur',
  },
  {
    text: i18n.t('mappings.onboarding.educationDegrees.technicalCollege'),
    value: 'Fachhochschulreife',
  },
  {
    text: i18n.t('mappings.onboarding.educationDegrees.middleCollege'),
    value: 'Mittlerer Schulabschluss',
  },
  {
    text: i18n.t('mappings.onboarding.educationDegrees.basicCollege'),
    value: 'Hauptschulabschluss',
  },
  {
    text: i18n.t('mappings.onboarding.educationDegrees.other'),
    value: 'Sonstige',
  },
  {
    text: i18n.t('mappings.onboarding.educationDegrees.none'),
    value: 'Keine',
  },
];
